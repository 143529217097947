import { useQuery, useMutation, useQueryClient } from 'react-query';

import axios from 'axios';


export const useScorecardRequests = ({ evidence, contributor }={}) => {
  const params = { evidence, contributor };
  return useQuery(['scorecard_requests', params], () => 
      axios.get('/api/scorecards/requests/', { params }).then(({ data }) => data)
  );
};

export const useAddScorecardRequest = (evidenceId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (scorer) =>
      axios
        .post('/api/scorecards/requests/', { evidence: evidenceId, scorer })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        // let's wait for these
        return Promise.all([
          queryClient.invalidateQueries(['contributors', { forEvidence: evidenceId }]),
          queryClient.invalidateQueries(['scorecard_requests', { evidence: evidenceId }]),
          queryClient.invalidateQueries(['evidence', evidenceId]),
          // TODO maybe provide the parameter so that we can invalidate just the ones we want
          queryClient.invalidateQueries(['evidence']),
        ]);
      },
    }
  );
};

export const useRevokeScorecardRequest = (evidenceId) => {
    const queryClient = useQueryClient();

    return useMutation(
      (id) => axios.delete(`/api/scorecards/requests/${id}/`),
      {
        onSuccess: () => {
          // let's wait for these
          return Promise.all([
            queryClient.invalidateQueries(['contributors', { forEvidence: evidenceId }]),
            queryClient.invalidateQueries(['scorecard_requests', { evidence: evidenceId }]),
            queryClient.invalidateQueries(['evidence', evidenceId]),
            // TODO maybe provide the parameter so that we can invalidate just the ones we want
            queryClient.invalidateQueries(['evidence']),
          ]);
        },
      }
    );
  };


export const useFulfillScorecardRequest = (evidenceId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }) =>
      axios
        .post(`/api/scorecards/requests/${id}/fulfill/`, data)
        .then(({ data }) => data),
    {
      onSuccess: () => {
        // we can be lazy about this, not needed directly
        queryClient.invalidateQueries(['parameters']);

        // let's wait for these
        return Promise.all([
          queryClient.invalidateQueries(['contributors', { forEvidence: evidenceId }]),
          queryClient.invalidateQueries(['scorecard_requests', { evidence: evidenceId }]),
          queryClient.invalidateQueries(['scorecards', 'list', evidenceId]),
          // TODO maybe provide the parameter so that we can invalidate just the ones we want
          queryClient.invalidateQueries(['evidence']),
        ]);
      },
    }
  );

};

import { Fragment, useEffect, useState } from 'react';
import { Switch, Route, Link, Redirect, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';

import { List, Segment, Header, Divider, Form, Button, Modal, Menu, Label, Icon, Popup, Item } from 'semantic-ui-react';

import { ParameterAdd, ParametersView, ParameterLabel } from './Parameter';

import { useProfile } from './queries/profile';
import { useAddProtocol, useDeleteProtocol, useProtocol, useProtocols, useUpdateProtocol } from './queries/protocols';
import { useFeature } from './queries/features';
import { useProtocolParameters } from './queries/parameters';

import qs from 'qs';
import _ from 'lodash';


export const PROTOCOL_TYPE_CHOICES = [
    { key: 1, text: 'In Vivo',  value: 'VIV' },
    { key: 2, text: 'In Vitro', value: 'VIT' },
];


const ProtocolAdd = ({ onClose, onAdded }) => {
  const history = useHistory();
  const { state: { returnTo } = {}, search } = useLocation();
  const { type: protocolType } = qs.parse(search, { ignoreQueryPrefix: true });

  const handleClose = () => {
    if (returnTo) {
      history.replace(returnTo);
    } else {
      onClose?.();
    }
  };

  const [protocol, setProtocol] = useState(() => ({
    protocol_type: protocolType,
  }));

  const addProtocol = useAddProtocol();
  const handleProtocolChange = (e, {name, value}) => {
    setProtocol({ ...protocol, [name]: value });
  };

  const handleProtocolSubmit = () =>
    addProtocol.mutate(protocol, {
      onSuccess: onAdded
    });

  const { name, description, protocol_type } = protocol;

  return (
    <Modal centered={false} dimmer="inverted" open onClose={onClose}>
      <Modal.Header>New Protocol</Modal.Header>
      <Modal.Content>
        <Form id="createProtocolForm" onSubmit={handleProtocolSubmit}>
          <Form.Group>
            <Form.Input required name="name" label="Name" value={name} width={10} onChange={handleProtocolChange} />
            <Form.Dropdown required selection width={6}
              name="protocol_type"
              label="Protocol type"
              value={protocol_type}
              options={PROTOCOL_TYPE_CHOICES}
              onChange={handleProtocolChange}
            />
          </Form.Group>

          <Form.TextArea name="description" label="Description" value={description} rows={5} onChange={handleProtocolChange} />

        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button form="createProtocolForm" primary icon="save" content="Save" />
      </Modal.Actions>

    </Modal>
  );
};

export const Protocol = ({ indexPath='/protocols' }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { protocolId } = useParams();

  const protocol = useProtocol(protocolId);

  // form data state
  const [protocolData, setProtocolData] = useState();
  useEffect(() => {
    if (protocol.data) {
      setProtocolData(protocol.data);
    }
  }, [protocol.data]);

  const handleProtocolChange = (e, {name, value}) => {
    setProtocolData((data) => ({ ...data, [name]: value }));
  };

  // mutations
  const updateProtocol = useUpdateProtocol(protocolId);
  const handleProtocolSubmit = () =>
    updateProtocol.mutate(protocolData, {
      onSuccess: () => history.push(match.url)
    });

  const deleteProtocol = useDeleteProtocol();
  const handleRemove = () =>
    deleteProtocol.mutate(protocolId, {
      onSuccess: () => history.replace(indexPath),
      onError: (err) => alert(JSON.stringify(err))
    });

  // render
  const { data: profile } = useProfile();
  const canModify = protocol.data?.is_owned || profile?.is_staff;

  const { state: { returnTo } = {} } = useLocation();

  return (
    <Segment loading={protocol.isLoading}>
      <Header dividing as="h2">
        {protocol.data?.name}
        <Label color="teal">{_.find(PROTOCOL_TYPE_CHOICES, { value: protocol.data?.protocol_type })?.text}</Label>
        {returnTo && (
          <Button size="small" compact primary floated="right"
            content="Return to explore"
            as={Link} to={returnTo}
          />
        )}
      </Header>

      <Switch>
        <Route path={`${match.path}/edit`}>
          { canModify ? (
                <Form onSubmit={handleProtocolSubmit}>
                  <Form.Group>
                    <Form.Input required name="name" label="Name" value={protocolData?.name} width={10} onChange={handleProtocolChange} />
                    <Form.Dropdown required selection width={6}
                      name="protocol_type"
                      label="Protocol type"
                      value={protocolData?.protocol_type}
                      options={PROTOCOL_TYPE_CHOICES}
                      onChange={handleProtocolChange}
                    />
                  </Form.Group>

                  <Form.TextArea name="description" label="Description" value={protocolData?.description} rows={5} onChange={handleProtocolChange} />

                  <Button floated="right" primary icon="save" content="Save"/>
                  <Button floated="right" type="button" icon="cancel" content="Cancel" as={Link} to={match.url} />
                </Form>
            )
            : 
            <Redirect to={match.url} />
          }
        </Route>
        <Route>
          <p>{ protocol.data?.description }</p>
          { canModify &&
            <Button floated="right" icon="edit" content="Edit description" as={Link} to={`${match.url}/edit`} />
          }
        </Route>
      </Switch>

      <Divider hidden clearing />
      <Divider />

      { protocol.data?.parameters_count > 0 && <ParametersView /> }

      <Divider hidden section />

      { profile &&
        <Button size="large" floated="right" positive as={Link} to={`${match.url}/parameters/new`} icon="plus" content="Add Factor" />
      }

      <Divider fitted hidden clearing />

      <Route path={`${match.path}/parameters/new`}>
        <ParameterAdd
          onAdded={(data) => history.replace(`${match.url}/parameters/${data.id}`)}
          modal onClose={() => history.replace(match.url)}
        />
      </Route>

      { protocol.data?.references_count === 0 && canModify &&
        <Fragment>
          <Divider />
          <Button floated="left" negative icon="trash" content="Remove" onClick={handleRemove} />
          <Divider hidden fitted clearing />
        </Fragment>
      }

    </Segment>
  );
};

export const ProtocolListItem = ({ baseUrl, protocol }) => (
  <List.Item key={protocol?.id}>
    <List.Content as={Link} to={`${baseUrl}/${protocol?.id}`}>
      <List.Header as="h4">
        {protocol?.name}{' '}
        {protocol?.is_owned && (
          <Popup
            trigger={<Icon name="user" color="green" />}
            content="Contributed by current user"
            position="top center"
          />
        )}
      </List.Header>
      <List.Description>{protocol?.description}</List.Description>
      <List.Description>&nbsp;</List.Description>
    </List.Content>
  </List.Item>
);

export const ProtocolList = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const { data: profile } = useProfile();

  const location = useLocation();
  const { type: protocolType = 'VIV' } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const protocols = useProtocols({ protocol_type: protocolType });
  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <ProtocolAdd
          onAdded={(data) => history.replace(`${match.url}/${data.id}`)}
        />
      </Route>

      <Route path={`${match.path}/:protocolId(\\d+)`}>
        <Protocol indexPath={match.path} />
      </Route>

      <Route>
        <Header as="h2" className="peers-blue">
          Protocols
        </Header>

        <Button.Group fluid size="large">
          <Button
            toggle
            as={Link}
            color={protocolType === 'VIV' ? 'teal' : undefined}
            to={{ search: '?type=VIV' }}
            exact
          >
            In Vivo
          </Button>
          <Button
            toggle
            as={Link}
            color={protocolType === 'VIT' ? 'teal' : undefined}
            to={{ search: '?type=VIT' }}
            exact
          >
            In Vitro
          </Button>
        </Button.Group>

        <Divider />

        <List selection>
          {_.map(protocols.data, (p) => (
            <ProtocolListItem key={p.id} protocol={p} baseUrl={match.url} />
          ))}
        </List>

        {profile && (
          <Menu text floated="right" size="large">
            <Menu.Item>
              <Button
                positive
                icon="plus"
                content="Add Protocol"
                as={Link}
                to={{
                  pathname: `${match.url}/new`,
                  search: `?type=${protocolType}`,
                  state: { returnTo: location },
                }}
              />
            </Menu.Item>
          </Menu>
        )}
      </Route>
    </Switch>
  );
};


const ProtocolItem = ({ protocolId, featureId, parameters }) => {
  const protocol = useProtocol(protocolId);
  const { name, description, is_owned, protocol_type } = protocol.data ?? {};

  let headerLink = `/protocols/${protocolId}`;
  if (featureId) {
    // if we show Protocol for a single Factor, just go there
    headerLink += `/parameters/${parameters[0].id}`;
  }

  const location = useLocation();
  const linkTo = {
    state: {
      returnTo: { pathname: location.pathname, search: location.search },
    },
  };

  return (
    <Item>
      <Item.Content as={featureId ? Link : undefined} to={{ pathname: featureId ? headerLink : undefined, ...linkTo }}>
        <Item.Header as={featureId ? undefined : Link} to={{ pathname: featureId ? undefined : headerLink, ...linkTo }}>
          {name}{' '}
          <Label
            horizontal
            color="teal"
            content={
              PROTOCOL_TYPE_CHOICES.find((c) => c.value === protocol_type)?.text
            }
          />{' '}
          {featureId && <ParameterLabel parameter={parameters[0]} />}
          {is_owned && (
            <Popup
              trigger={<Icon name="user" color="green" />}
              content="Contributed by current user"
              position="top center"
            />
          )}{' '}
        </Item.Header>
        <Item.Meta>{description}</Item.Meta>
        <Item.Meta>
          <Divider hidden />
          {featureId ? null : (
            <Label.Group size="large">
              {parameters?.map((parameter) => (
                <ParameterLabel key={parameter.id} parameter={parameter} linkTo={linkTo} />
              ))}
            </Label.Group>
          )}
        </Item.Meta>
      </Item.Content>
    </Item>
  );
};


const ProtocolsForFeatureHeader = ({ featureId, ...props }) => {
  const feature = useFeature(featureId);
  return feature.data ? (
    <Header {...props}>
      Protocols for Factor "<u>{feature.data.name}</u>" in category "<u>{feature.data.category.name}</u>"
      <Header.Subheader>Select a protocol to view details</Header.Subheader>
    </Header>
  ) : null;
};


export const ProtocolParameterList = () => {
  const location = useLocation();
  const { factor: featureId, search } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const parameters = useProtocolParameters({ feature: featureId, search });

  // if not searching, we want to only show the ones affecting / not affecting,
  // ordered by most affecting first
  const parameterData =
    featureId || search
      ? parameters.data
      : _.filter(parameters.data, (p) => p.score !== null);

  const parameterGroups = _.groupBy(parameterData, 'protocol');

  return (
    <>
      {featureId ? (
        <ProtocolsForFeatureHeader as="h2" className="peers-blue" featureId={featureId} />
      ) : search ? (
        <Header as="h2" className="peers-blue">Protocols for Factors matching "<u>{search}</u>"
          <Header.Subheader>Select a protocol to view details</Header.Subheader>
        </Header>
      ) : (
        <Header as="h2" className="peers-blue">Explore Protocols and Factors
          <Header.Subheader>Select a protocol to view details</Header.Subheader>
        </Header>
      )}

      <Divider hidden />

      <Item.Group divided relaxed link={!!featureId}>
        {_.map(parameterGroups, (parameters, protocolId) => (
          <ProtocolItem
            key={protocolId}
            featureId={featureId}
            protocolId={protocolId}
            parameters={parameters}
          />
        ))}
      </Item.Group>

      <Divider hidden clearing />
    </>
  );
};

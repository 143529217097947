import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';


export const useProtocolParameters = ({ protocol, reference, feature, search }) => {
  const params = { protocol, reference, feature, search };
  return useQuery(['parameters', params], () =>
    axios.get('/api/parameters/', { params }).then(({ data }) => data)
  );
};

export const useParameter = (id) =>
  useQuery(['parameters', id], () =>
    axios.get(`/api/parameters/${id}/`).then(({ data }) => data)
  );


export const useAddParameter = (protocolId) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ feature, customFeature, category, customCategory, range }) => {
      // when adding for an existing feature, the parameter may already be there, so let's check
      if (feature || customFeature) {
        const { data: parameters } = await axios.get('/api/parameters/', {
          params: {
            protocol: protocolId,
            feature,
            feature_name: customFeature,
          },
        });

        // if found, return it (we expect it to be unique)
        if (parameters.length) return parameters[0];
      }

      // else create and return it
      const { data } = await axios.post('/api/parameters/', {
        protocol: protocolId,
        feature: feature ?? null,
        feature_name: customFeature || null,
        category: category ?? null,
        category_name: customCategory || null,
        range,
      });

      return data;
    },
    {
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(['parameters', { protocol: protocolId }]),
        queryClient.invalidateQueries(['protocols', protocolId]),
      ]),
    }
  );
};

export const useUpdateParameter = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ is_public, range }) =>
      axios
        .patch(`/api/parameters/${id}/`, { is_public, range })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['parameters', id], data);
        queryClient.invalidateQueries(['parameters']);
      },
    }
  );
};

export const useDeleteParameter = (protocolId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/parameters/${id}/`),
    {
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(['parameters', { protocol: protocolId }]),
        queryClient.invalidateQueries(['protocols', protocolId]),
      ]),
    }
  );
};

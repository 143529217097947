import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, Form, Message, Modal, Container } from 'semantic-ui-react';

import { isEmpty } from 'lodash';
import axios from 'axios';
import qs from 'qs';


export default function Register() {
  const location = useLocation();
  const { returnTo='/' } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const history = useHistory();

  const [params, setParams] = useState({});
  const handleChange = ({ target: { name, value } }) => {
    setParams((prevParams) => ({ ...prevParams, [name]: value }));
  };

  const [error, setError] = useState(null);
  const [completed, setCompleted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('/api/auth/users/', params).then(
      () => setCompleted(true),
      (err) => {
        if (!isEmpty(err.response?.data)) {
          const lines = [];
          for (const field in err.response.data) {
            lines.push(...err.response.data[field]);
          }
          setError(lines);
        } else {
          setError([]);
        }
      }
    );
  };

  // clear error lines
  useEffect(() => setError(null), [params]);

  return (
    <Modal
      open
      dimmer="inverted"
      centered={false}
      size="small"
      onClose={() => history.replace(returnTo)}
    >
      <Modal.Header>
        {completed ? 'Your account has been created' : 'Register with PEERS'}
      </Modal.Header>
      <Modal.Content>
        {completed ? (
          <Container text textAlign="center">
            <p>Thank you for your interest in our platform.</p>
            <p>
              To complete your registration, you need to verify the email
              address you have provided.
            </p>
            <p>
              We have sent an email to that address with further instructions to
              activate your account.
            </p>
          </Container>
        ) : (
          <Form
            id="registerForm"
            onSubmit={handleSubmit}
            error={error !== null}
          >
            <Form.Input
              required
              name="first_name"
              label="First name"
              onChange={handleChange}
            />
            <Form.Input
              required
              name="last_name"
              label="Last name"
              onChange={handleChange}
            />

            <Form.Input
              required
              name="email"
              label="Email address"
              input={{ inputMode: 'email' }}
              onChange={handleChange}
            />
            <Form.Input
              required
              name="password"
              label="Password"
              type="password"
              onChange={handleChange}
            />
            <Form.Input
              required
              name="re_password"
              label="Repeat password"
              type="password"
              onChange={handleChange}
            />

            <Form.Input
              required
              name="username"
              label="User name"
              onChange={handleChange}
            />

            <Message
              error
              header="Could not register your account, please try again."
              list={error}
            />
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        {!completed && (
          <Button form="registerForm" primary content="Register" />
        )}
        <Button
          as={Link}
          to={returnTo}
          content={completed ? 'Return' : 'Cancel'}
        />
      </Modal.Actions>
    </Modal>
  );
}

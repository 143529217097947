import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Form, Search } from 'semantic-ui-react';

import { useDebounce } from '../hooks/useDebounce';

import { useProtocols } from '../queries/protocols';

import qs from 'qs';
import _ from 'lodash';

export default function ProtocolSearchBox({ size = 'large', ...props }) {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const searchProtocols = useProtocols({ search: debouncedSearchTerm });

  const handleSelectProtocol = (e, { result }) => {
    setSearchTerm(result?.title || '');
    const goTo = {
      search: '?' + qs.stringify({ protocol: result.id }),
    };
    history.push(goTo);
  };

  const searchResults = _.map(searchProtocols.data, (p) => ({
    id: p.id,
    title: p.name,
    description: p.description,
  }));

  return (
    <Form size={size} {...props}>
      <Form.Field>
        <Search
          input={{ iconPosition: 'left' }}
          fluid
          loading={searchProtocols.isLoading}
          showNoResults={false}
          minCharacters={0}
          placeholder="Type to search for a Protocol"
          value={searchTerm}
          onSearchChange={(e, { value }) => setSearchTerm(value)}
          results={searchResults}
          onResultSelect={handleSelectProtocol}
        />
      </Form.Field>
    </Form>
  );
}

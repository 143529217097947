import { useState, useEffect, useMemo } from 'react';
import { Link, Route, useRouteMatch, useParams, useHistory  } from 'react-router-dom';

import { Modal, Button, Segment, Header, Divider, Form, Table, Card, Icon, Label, Popup, Radio, Loader, Message } from 'semantic-ui-react';

import { useProfile } from './queries/profile';
import { useAddScorecard, useDeleteScorecard, useScorecard, useScorecards, useUpdateScorecard } from './queries/scorecards';
import { useScorecardRequests, useAddScorecardRequest, useRevokeScorecardRequest, useFulfillScorecardRequest } from './queries/scorecardRequests';
import { useContributors } from './queries/contributors';

import _ from 'lodash';


const scoringFields = [
  'study_design',
  'sample_size',
  'inclusion_criteria',
  'randomisation',
  'blinding',
  'outcome_measures',
  'statistical_methods',
  'experimental_animals',
  'experimental_procedures',
  'results',
  'appropriate_data',
  'appropriate_conclusions',
];


const QuestionCard = ({ children, header, icon="question", name, value, onChange, readOnly, size }) =>
  <Card raised centered>
    <Card.Content>
      <Card.Header as="h4"><Icon name={icon} /> { header }</Card.Header>
    </Card.Content>
    <Card.Content>
      { children }
    </Card.Content>
    <Card.Content>
      <Form.Group inline>
        <Form.Field required={!readOnly}><label>Score:</label></Form.Field>

        <Form.Field className="custom-radio">
          <div className="ui checkbox radio">
            <input type="radio" className="hidden"
              id={name + '-0'}
              name={name}
              // eslint-disable-next-line eqeqeq
              checked={value == 0}
              value="0"
              onChange={onChange}
              disabled={readOnly}
              required
            />
            <label htmlFor={name + '-0'}>0</label>
          </div>
        </Form.Field>
        <Form.Field className="custom-radio">
          <div className="ui checkbox radio">
            <input type="radio" className="hidden"
              id={name + '-h'}
              name={name}
              // eslint-disable-next-line eqeqeq
              checked={value == 0.5}
              value="0.5"
              onChange={onChange}
              disabled={readOnly}
              required
            />
            <label htmlFor={name + '-h'}>{'\u00bd'}</label>
          </div>
        </Form.Field>
        <Form.Field className="custom-radio">
          <div className="ui checkbox radio">
            <input type="radio" className="hidden"
              id={name + '-1'}
              name={name}
              // eslint-disable-next-line eqeqeq
              checked={value == 1}
              value="1"
              onChange={onChange}
              disabled={readOnly}
              required
            />
            <label htmlFor={name + '-1'}>1</label>
          </div>
        </Form.Field>
        
      </Form.Group>
    </Card.Content>
  </Card>
;


const ScorecardForm = ({ scorecard, scorer, onClose, onSave, onRemove }) => {
  // form data state
  const [scorecardData, setScorecardData] = useState(() => ({
    additional_comments: '',
    affects_results: true,
  }));

  // fill in initial data if provided
  useEffect(() => {
    if (scorecard) {
      setScorecardData(scorecard);
    }
  }, [scorecard]);

  // fields are updated in state
  const handleFieldChange = (e, { name, value }) =>
    setScorecardData((previous) => ({ ...previous, [name]: value }));

  const handleRadioChange = ({ target: { name, value } }) =>
    setScorecardData((previous) => ({ ...previous, [name]: value }));

  const handleSave = () => onSave(scorecardData);

  // these can change
  const {
    study_design,
    sample_size,
    inclusion_criteria,
    randomisation,
    blinding,
    outcome_measures,
    statistical_methods,
    experimental_animals,
    experimental_procedures,
    results,

    appropriate_data,
    appropriate_conclusions,

    affects_results,
    additional_comments, 
  } = scorecardData;

  // show score logic
  const score = useMemo(() => {
    const totalScore = scoringFields.reduce(
      (score, field) => {
        const fieldScore = scorecardData[field];
        // skip adding empty values
        if (!fieldScore) return score;

        if (typeof fieldScore === 'number') {
          return score + fieldScore;
        } else if (typeof fieldScore === 'string') {
          return score + Number.parseFloat(fieldScore);
        }
        // else don't add it
        return score;
      },
      0.0
    );

    return scorecardData['affects_results'] ? totalScore : -totalScore;
  }, [scorecardData]);

  const canSave = !!onSave;
  const canRemove = !!onRemove;

  return (
    <Modal centered={false} dimmer="inverted" size="large" open onClose={onClose}>
      <Modal.Header>Scores provided by { (scorer?.is_public || canSave) ? scorer?.username : <Label content="Anonymous" /> }
        <Label floating color="grey" size="big" content={score} />
      </Modal.Header>

      <Modal.Content scrolling>
        <Form id="scorecardForm" onSubmit={handleSave}>
          <Header as="h3">Methods domains (based on ARRIVE 2.0)</Header>
          <Segment basic as={Card.Group} itemsPerRow={2} doubling>
            <QuestionCard header="Study design" icon="clipboard"
              readOnly={!canSave}
              name="study_design"
              value={study_design}
              onChange={handleRadioChange}
            >
              For each experiment, are brief details of study design provided, including:
              <ol type="a">
                <li>The groups being compared, including control groups? If no control group has been used, is the rationale stated?</li>
                <li>The experimental unit (e.g. a single animal, litter, or cage of animals)?</li>
              </ol>
            </QuestionCard>

            <QuestionCard header="Sample size" icon="search"
              readOnly={!canSave}
              name="sample_size"
              value={sample_size}
              onChange={handleRadioChange}
            >
              <ol type="a">
                <li>Are the exact number of experimental units allocated to each group, and the total number in each experiment mentioned?</li>
                <li>Is the sample size provided and the rationale for it?</li>
              </ol>
            </QuestionCard>

            <QuestionCard header="Inclusion and exclusion criteria" icon="tasks"
              readOnly={!canSave}
              name="inclusion_criteria"
              value={inclusion_criteria}
              onChange={handleRadioChange}
            >
              <ol type="a">
                <li>Were all criteria used for including and excluding animals (or experimental units) during the experiment, and data points during the analysis mentioned ?</li>
                <li>If any animals or data points or experimental units were excluded, was this reported and explained? </li>
                <li>For each analysis, was the exact value of n in each experimental group reported?</li>
              </ol>
            </QuestionCard>

            <QuestionCard header="Randomisation" icon="random"
              readOnly={!canSave}
              name="randomisation"
              value={randomisation}
              onChange={handleRadioChange}
            >
              <ol type="a">
                <li>Was randomization used to allocate experimental units to control and treatment groups? If done, was the method mentioned?</li>
                <li>Was the strategy used to minimize potential confounders such as the order of treatments and measurements, or animal/cage location mentioned?</li>
                <li>If confounders were not controlled was this stated explicitly?</li>
              </ol>
            </QuestionCard>
            
            <QuestionCard header="Blinding" icon="hide"
              readOnly={!canSave}
              name="blinding"
              value={blinding}
              onChange={handleRadioChange}
            >
              Was blinding done during the allocation, the conduct of the experiment, the outcome assessment, and the data analysis and if so how?
            </QuestionCard>

            <QuestionCard header="Outcome measures" icon="sitemap"
              readOnly={!canSave}
              name="outcome_measures"
              value={outcome_measures}
              onChange={handleRadioChange}
            >
              <ol type="a">
                <li>Have all outcome measures assessed been mentioned? (e.g. cell death, molecular markers, or behavioral changes)</li>
                <li>For hypothesis-testing studies, has the primary outcome measure, i.e. the outcome measure that was used to determine the sample size been mentioned?</li>
              </ol>
            </QuestionCard>

            <QuestionCard header="Statistical methods" icon="chart bar"
              readOnly={!canSave}
              name="statistical_methods"
              value={statistical_methods}
              onChange={handleRadioChange}
            >
              <ol type="a">
                <li>Have all details of the statistical methods used for each analysis, including software used been provided? </li>
                <li>Were any methods used to assess whether the data met the assumptions of the statistical approach described, and what was done if the assumptions were not met?              </li>
              </ol>
            </QuestionCard>

            <QuestionCard header="Experimental animals" icon="paw"
              readOnly={!canSave}
              name="experimental_animals"
              value={experimental_animals}
              onChange={handleRadioChange}
            >
              <ol type="a">
                <li>Were species-appropriate details of the animals used, including species, strain and substrain, sex, age or developmental stage, and, if relevant, weight described?</li>
                <li>Was further relevant information on the provenance of animals, health/immune status, genetic modification status, genotype, any previous procedures etc. mentioned?</li>
              </ol>
            </QuestionCard>

            <QuestionCard header="Experimental procedures" icon="lab"
              readOnly={!canSave}
              name="experimental_procedures"
              value={experimental_procedures}
              onChange={handleRadioChange}
            >
              <p>For each experimental group, including controls, were experimental procedures mentioned in enough detail to allow others to replicate them, including:</p>

              <ol type="a">
                <li>What was done, how it was done and what was used?</li>
                <li>When and how often?</li>
                <li>Where (including detail of any acclimatization periods)?</li>
                <li>Why (provide rationale for procedures)?</li>
              </ol>
            </QuestionCard>

            <QuestionCard header="Results" icon="chart line"
              readOnly={!canSave}
              name="results"
              value={results}
              onChange={handleRadioChange}
            >
              <p>For each experiment conducted, including independent replications, were:</p>
              <ol type="a">
                <li>Summary/descriptive statistics for each experimental group, with a measure of variability where applicable (e.g. mean and SD, or median and range) reported?</li>
                <li>If applicable, was the effect size with a confidence interval mentioned?</li>
              </ol>
            </QuestionCard>
          </Segment>

          <Header as="h3">Results domain &mdash; Based on appropriate interpretation of data</Header>
          <Segment basic>
            <Card fluid raised>
              <Card.Content>
                <Header as="h3" content="Data interpretation and analysis" icon="chart area" />
                <Divider />
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        How appropriate was the data and statistical analysis performed and reported in results?
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Form.Input inline label="Score:" required={canSave}
                          input={{ type: 'number', min: 0, max: 5, step: 0.5, size: 7, readOnly: !canSave }}
                          name="appropriate_data"
                          value={appropriate_data}
                          onChange={handleFieldChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        How appropriate and suitable were the conclusions and inferences made?
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Form.Input inline label="Score:" required={canSave}
                          input={{ type: 'number', min: 0, max: 5, step: 0.5, size: 7, readOnly: !canSave }}
                          name="appropriate_conclusions"
                          value={appropriate_conclusions}
                          onChange={handleFieldChange}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Content>
            </Card>

          </Segment>

          <Divider />

          <Form.Group>
            <Form.Field width={6}>
              <label>Evidence suggests the Factor affects the results</label>
              <Form.Field className="custom-radio">
                <Radio
                  name="affects_results"
                  checked={affects_results === true}
                  value={true}
                  label="Affects"
                  onChange={handleFieldChange}
                  disabled={!canSave}
                />
              </Form.Field>
              <Form.Field className="custom-radio">
                <Radio
                  name="affects_results"
                  checked={affects_results === false}
                  value={false}
                  label="Does not affect"
                  onChange={handleFieldChange}
                  disabled={!canSave}
                />
              </Form.Field>
            </Form.Field>

            <Form.Input readOnly name="score" label="Total score" value={score} width={4} />
          </Form.Group>

          <Form.TextArea
            name="additional_comments"
            label="Additional comments"
            value={additional_comments}
            onChange={canSave ? handleFieldChange : undefined}
          />

        </Form>
      </Modal.Content>
      <Modal.Actions>
        { canRemove &&
          <Button negative floated="left" icon="trash" content="Remove" onClick={onRemove} />
        }
        <Button content={ canSave ? 'Cancel' : 'Close'} onClick={onClose} />
        { canSave &&
          <Button form="scorecardForm" primary content="Save" />
        }
      </Modal.Actions>
    </Modal>
  );
};

export const Scorecard = ({ onClose, onRemoved }) => {
  const { evidenceId, scorecardId } = useParams();
  const scorecard = useScorecard(scorecardId);

  const updateScorecard = useUpdateScorecard(scorecardId);
  const handleSave = (data) =>
    updateScorecard.mutate(data, {
      onSuccess: onClose
    });

  const deleteScorecard = useDeleteScorecard(evidenceId);
  const handleRemove = () => deleteScorecard.mutate(scorecardId, {
    onSuccess: onRemoved,
    onError: (err) => {
      // TODO show proper error message
      alert(JSON.stringify(err));
    }
  });

  const { data: profile } = useProfile();
  
  const canSave = scorecard.data?.is_owned;
  const canRemove = canSave || profile?.is_staff;

  const scorer = scorecard.data && {
    username: scorecard.data.scorer_name,
    competence: scorecard.data.scorer_competence,
  };

  return (
    <ScorecardForm
      scorecard={scorecard.data}
      scorer={scorer}
      onSave={canSave ? handleSave: undefined}
      onRemove={canRemove ? handleRemove : undefined}
      onClose={onClose}
    />
  );
};

export const ScorecardAdd = ({ onClose, onSave }) => {
  const { evidenceId, requestId } = useParams();

  // save either simply adds or fulfills a request
  const addScorecard = useAddScorecard(evidenceId);
  const fulfillScorecardRequest = useFulfillScorecardRequest(evidenceId);

  const handleSave = (data) => {
    if (requestId) {
      fulfillScorecardRequest.mutate({ id: requestId, data }, {
        onSuccess: onSave
      });
    } else {
      addScorecard.mutate(data, {
        onSuccess: onSave
      });
    }
  };

  const { data: profile } = useProfile();
  // under some circumstances, we may arrive at this form,
  // even though the current user already has a Scorecard
  // let's check this out and close this modal instead
  const scorecards = useScorecards(
    { evidence: evidenceId, contributor: profile?.contributor },
    { enabled: !!profile }
  );

  useEffect(() => {
    if (scorecards.data?.length === 1) {
      onClose();
    }
  }, [scorecards.data, onClose]);

  if (scorecards.isLoading) return <Loader active />;

  return (
    <ScorecardForm
      scorer={profile}
      onSave={handleSave}
      onClose={onClose}
    />
  );
}


const ContributorScorecardRequest = ({ profile, fallback }) => {
  const { evidenceId } = useParams();
  const match = useRouteMatch();

  const history = useHistory();
  const evidenceRequests = useScorecardRequests({ evidence: evidenceId, contributor: profile?.contributor });

  // should be unique
  const evidenceRequest = evidenceRequests.data?.[0];

  return (
    evidenceRequest ? <>
      <Message info size="small">
        <Button floated="right" positive icon="plus" content="Add Scorecard" as={Link} to={`${match.url}/requests/${evidenceRequest.id}`} />
        <Message.Content>
          <Message.Header>You have a pending Scorecard request for this Factor Reference</Message.Header>
          <Divider fitted hidden clearing />
        </Message.Content>
      </Message>

      <Route path={`${match.path}/requests/:requestId`}>
        <ScorecardAdd onClose={() => history.push(match.url)} onSave={() => history.replace(match.url)} />
      </Route>
    </>
    : fallback ?? null
  );
}


const ScorecardRequests = () =>  {
  const { evidenceId } = useParams();

  const { data: profile } = useProfile();
  const evidenceRequests = useScorecardRequests({ evidence: evidenceId });

  const revokeRequest = useRevokeScorecardRequest(evidenceId);

  return (<>
    { profile.is_coordinator && <ScorecardRequestPopup floated="right" secondary icon="plus" content="Request Scorecard" /> }
    { evidenceRequests.data?.length > 0 && <>
      <Header as="h4">Requests</Header>
      <Table structured selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Scorer</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { _.map(evidenceRequests.data, ({ id, is_owned, scorer, }) =>
              <Table.Row key={id}>
                <Table.Cell>{is_owned && <Popup trigger={<Icon name="user" color="green" />} content="Request addressed to current user" position="top center" />}</Table.Cell>
                <Table.Cell>{scorer.username}</Table.Cell>
                <Table.Cell collapsing>
                  { profile.is_coordinator && <Button negative icon="cancel" title="Revoke request" onClick={() => revokeRequest.mutate(id)} />  }
                </Table.Cell>
              </Table.Row>
            )
          }
        </Table.Body>
      </Table>
    </> }
    <Divider fitted hidden clearing />
  </>);
};


const ScorecardRequestPopup = ({ onAdded, ...props }) => {
  const { evidenceId } = useParams();
  const requestScorecard = useAddScorecardRequest(evidenceId)
  const contributors = useContributors({ forEvidence: evidenceId });

  const [open, setOpen] = useState(false);

  const [selectedContributor, setSelectedContributor] = useState(null);
  const options = _.map(contributors.data, (s) => ({
    key: s.id, value: s.id, text: s.username
  }));

  const handleSubmit = () => {
    requestScorecard.mutate(selectedContributor, {
      onSuccess: (data) => {
        setOpen(false);
        onAdded?.(data);
      }
    });
  };

  return (
    <Popup trigger={<Button {...props} onClick={() => setOpen(true)} />}
      open={open} onClose={() => setOpen(false)}
      on="click" wide="very" position="top right"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Dropdown labeled
          label="Choose a scorer:" selection 
          options={options}
          value={selectedContributor}
          onChange={(e, { value }) => setSelectedContributor(value)}
        />
        <Form.Group inline>
          <Form.Button icon="send" primary content="Send request" disabled={!selectedContributor} />
          <Form.Button type="button" icon="cancel" content="Cancel" onClick={() => setOpen(false)} />
        </Form.Group>
      </Form>
    </Popup>
  );
};


export const ScorecardTable = () =>  {
  const { evidenceId } = useParams();

  const match = useRouteMatch();

  const { data: profile } = useProfile();
  const scorecards = useScorecards({ evidence: evidenceId });
  const addScorecardDisabled = _.some(scorecards.data, ({ is_owned }) => is_owned);

  return (<>
    { profile &&
      <ContributorScorecardRequest profile={profile} fallback={<Button
        floated="right" positive icon="plus" content="Add Scorecard"
        as={Link} to={`${match.url}/scorecards/new`}
        disabled={addScorecardDisabled}
      />} />
    }

    { scorecards.data?.length > 0 && <>
      <Header as="h4">Scorecards</Header>
      <Table structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Scorer</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Score</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { _.map(scorecards.data, ({ id, is_owned, score, scorer, }) =>
              <Table.Row key={id}>
                <Table.Cell>{is_owned && <Popup trigger={<Icon name="user" color="green" />} content="Contributed by current user" position="top center" />}</Table.Cell>
                <Table.Cell>{(scorer.is_public || is_owned) ? scorer.username : <Label content="Anonymous" />}</Table.Cell>
                <Table.Cell textAlign="right">{score}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Button as={Link} to={`${match.url}/scorecards/${id}`} icon="info" primary title="Details" />
                </Table.Cell>
              </Table.Row>
            )
          }
        </Table.Body>
      </Table>
    </> }

    { profile?.is_coordinator && <ScorecardRequests /> }
  </>);
};

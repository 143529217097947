import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';


export const useProtocols = (params) =>
  useQuery(['protocols', params], () =>
    axios.get('/api/protocols/', { params }).then(({ data }) => data)
  );

  
export const useProtocol = (id) =>
  useQuery(
    ['protocols', id],
    () => axios.get(`/api/protocols/${id}/`).then(({ data }) => data),
    { enabled: !!id }
  );


export const useAddProtocol = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name, description, protocol_type }) =>
      axios
        .post('/api/protocols/', { name, description, protocol_type, parameters: [] })
        .then(({ data }) => data),
    { 
      onSuccess: () =>
        queryClient.invalidateQueries(['protocols']),
    }
  );
};

export const useUpdateProtocol = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name, description, protocol_type }) =>
      axios
        .patch(`/api/protocols/${id}/`, { name, description, protocol_type })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['protocols', id], data);
        return queryClient.invalidateQueries(['protocols']);
      },
    }
  );
};

export const useDeleteProtocol = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/protocols/${id}/`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['protocols']);
      }
    }
  );
};

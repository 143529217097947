import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';


export const useReferenceMetadata = (id, source='doi') =>
  useQuery(
    ['metadata', source, id],
    () => axios.get(`/api/references/metadata/${source}/${id}/`).then(({data}) => data),
    {
      enabled: false,
      retry: false,
    }
  );


export const useAvailableReferences = (parameterId) => {
  const params = { for_parameter: parameterId };
  return useQuery(['references', params], () =>
    axios.get('/api/references/', { params }).then(({ data }) => data)
  );
}

export const useReferences = ({
  protocol,
  search,
  paperType,
  post2010,
} = {}) => {
  const params = {
    protocol,
    search,
    paper_type: paperType,
    published_post_2010: post2010,
  };
  return useQuery(
    ['references', { protocol, search, paperType, post2010 }],
    () => axios.get('/api/references/', { params }).then(({ data }) => data),
    {
      keepPreviousData: true,
    }
  );
};

export const useReference = (id) =>
  useQuery(
    ['references', id],
    () => axios.get(`/api/references/${id}/`).then(({ data }) => data),
    {
      placeholderData: {
        doi: '',
        title: '',
        url: '',
        paper_type: 'ORG',
        year_published: null,
        published_post_2010: true,
      },
    }
  );


export const useAddReference = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ title, url, doi, pmid, paper_type, year_published }) =>
      axios
        .post('/api/references/', { title, url, doi, pmid, paper_type, year_published })
        .then(({ data }) => data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['references']);
      },
    }
  );
};

export const useUpdateReference = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ title, url, doi, pmid, paper_type, year_published }) =>
      axios
        .patch(`/api/references/${id}/`, { title, url, doi, pmid, paper_type, year_published })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['references', id], data);
        queryClient.invalidateQueries(['references']);
        queryClient.invalidateQueries(['evidence']);
      },
    }
  );
};

export const useDeleteReference = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/references/${id}/`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['references']);
        queryClient.invalidateQueries(['protocols']);
      },
    }
  );
};

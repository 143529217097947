import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';


export const useFeatureCategory = (id) =>
  useQuery(['categories', id], () =>
    axios.get(`/api/categories/${id}/`).then(({ data }) => data)
  );


export const useFeatureCategories = () =>
  useQuery(['categories'], () =>
    axios.get('/api/categories/').then(({ data }) => data)
  );


export const useAddFeatureCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name }) =>
      axios
        .post('/api/categories/', { name })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['categories']);
      },
    }
  );
};

export const useUpdateFeatureCategory = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name, is_public }) =>
      axios
        .patch(`/api/categories/${id}/`, { name, is_public })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['categories']);
      },
    }
  );
};

export const useDeleteFeatureCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/categories/${id}/`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['categories']);
      }
    }
  );
};


export const useFeatures = (categoryId) => {
  const params = categoryId ? { category: categoryId } : undefined;
  const queryKey = params ? ['features', params] : ['features'];

  return useQuery(queryKey, () =>
    axios.get('/api/features/', { params }).then(({ data }) => data)
  );
}

export const useSearchFeatures = ({ protocol, search }) => {
  search = search || '';

  return useQuery(['features', { protocol, search }], () =>
    axios.get('/api/features/', { params: { for_protocol: protocol, search } }).then(({ data }) => data), {
      keepPreviousData: true,
    }
  );
}

export const useFeature = (id) =>
  useQuery(['features', id], () =>
    axios.get(`/api/features/${id}/`).then(({ data }) => data),
    { enabled: !!id }
  );


export const useAddFeature = (categoryId) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name, protocol_type }) =>
      axios
        .post('/api/features/', { category: categoryId, name, protocol_type })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['features']);
      },
    }
  );
};

export const useUpdateFeature = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ name, category, protocol_type, is_generic, is_public }) =>
      axios
        .patch(`/api/features/${id}/`, {
          name,
          category,
          protocol_type,
          is_generic,
          is_public,
        })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['features', id], data);
        queryClient.invalidateQueries(['features']);
      },
    }
  );
};

export const useDeleteFeature = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/features/${id}/`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['features']);
      }
    }
  );
};


export const useAddFeatureAndCategory = () => {
  const queryClient = useQueryClient();
  const addFeatureCategory = useAddFeatureCategory();

  return useMutation(
    ({ category, feature }) =>
      addFeatureCategory
        .mutateAsync({ name: category })
        .then((newCategory) =>
          axios
            .post('/api/features/', { category: newCategory.id, name: feature })
            .then(({ data }) => data),
        ),
    {
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(['categories']),
        queryClient.invalidateQueries(['features']),
      ])
    }
  );
};

import { useEffect, useState } from 'react';
import {
  Link,
  Route,
  useRouteMatch,
  useHistory,
  useParams,
  NavLink,
  Switch,
  Redirect,
} from 'react-router-dom';

import {
  Button,
  Card,
  Divider,
  Form,
  Header,
  Icon,
  Image,
  Item,
  Menu,
  Message,
  Segment,
} from 'semantic-ui-react';

import { PasswordChangeForm } from './components/PasswordReset';
import { Scorecard, ScorecardAdd } from './Scorecard';
import { PAPER_TYPE_CHOICES } from './Reference';

import { useContributor, useContributors } from './queries/contributors';
import { useScorecards } from './queries/scorecards';
import { useScorecardRequests } from './queries/scorecardRequests';
import { useProfile, useUpdateProfile } from './queries/profile';

import _ from 'lodash';


export const SCORER_COMPETENCE_CHOICES = [
  { key: 1, value: 'NOV', text: 'Novice' },
  { key: 2, value: 'INT', text: 'Intermediate' },
  { key: 3, value: 'EXP', text: 'Expert' },
];

const pubmedLogo = `${process.env.PUBLIC_URL}/pubmedIcon.png`;
const doiLogo = `${process.env.PUBLIC_URL}/DOI_Logo_TM.png`;

function ContributorCard({ contributor, viewUrl, ...props }) {
  const dateJoined =
    contributor?.date_joined && new Date(contributor?.date_joined);
  return (
    <Card {...props}>
      <Card.Content>
        <Card.Header as={Link} to={viewUrl}>
          {contributor?.username}
        </Card.Header>
        <Card.Meta>Joined in {dateJoined?.toDateString()}</Card.Meta>
        <Card.Description>
          {contributor?.first_name} {contributor?.last_name}
        </Card.Description>
        {contributor?.email && (
          <Card.Description>
            <Icon name="mail" /> {contributor?.email}
          </Card.Description>
        )}
        <Card.Description>{contributor?.description}</Card.Description>
      </Card.Content>
      <Card.Content>
        <Icon name="list" />
        {contributor?.scorecards_count} scorecards
      </Card.Content>
    </Card>
  );
}

function ContributorScorecards({ profile }) {
  const history = useHistory();
  const match = useRouteMatch();

  const scorecards = useScorecards(
    { contributor: profile?.contributor || profile?.id },
    { enabled: !!profile }
  );
  if (scorecards.data?.length === 0) {
    return <Message compact content="There are no Scorecards." />;
  }

  const referenceScorecards = _.groupBy(scorecards.data, 'reference.id');

  return (
    <>
      <Card.Group itemsPerRow={2} stackable>
        {_.map(referenceScorecards, (scorecards, referenceId) => {
          const reference = scorecards[0].reference; // all have the same reference
          return (
            <Card key={referenceId}>
              <Card.Content>
                <Card.Header>{reference.title}</Card.Header>
                <Card.Meta>
                  {
                    _.find(PAPER_TYPE_CHOICES, { value: reference.paper_type })
                      ?.text
                  }
                </Card.Meta>
                <Card.Meta
                  style={{
                    color:
                      reference.published_post_2010 === null
                        ? 'red'
                        : undefined,
                  }}
                >
                  Published{' '}
                  {reference.published_post_2010 === null
                    ? '???'
                    : reference.published_post_2010
                    ? 'post-2010'
                    : 'pre-2010'}
                </Card.Meta>
                <Card.Description>
                  <a href={reference.url} target="_blank" rel="noreferrer">
                    <Image inline src={reference.doi ? doiLogo : pubmedLogo} />{' '}
                    {reference.doi || reference.pmid}
                  </a>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Item.Group divided>
                  {scorecards.map(({ id, score, protocol, feature }) => (
                    <Item key={id}>
                      <Item.Content>
                        <Button
                          basic
                          icon="star"
                          content={score?.toFixed(1) ?? 'N/A'}
                          floated="right"
                          as={Link}
                          to={`${match.url}/${id}`}
                        />
                        <Item.Header content={feature.name} />
                        <Item.Meta content={protocol.name} />
                      </Item.Content>
                    </Item>
                  ))}
                </Item.Group>
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>

      <Route path={`${match.path}/:scorecardId`}>
        <Scorecard
          onClose={() => history.push(match.url)}
          onRemoved={() => history.replace(match.url)}
        />
      </Route>
    </>
  );
}

function ContributorRequests({ profile }) {
  const match = useRouteMatch();
  const history = useHistory();

  const scorecardRequests = useScorecardRequests(
    { contributor: profile?.contributor || profile?.id },
    { enabled: !!profile }
  );
  if (scorecardRequests.data?.length === 0) {
    return (
      <Message compact content="There are no Scorecard requests pending." />
    );
  }

  const referenceScorecardRequests = _.groupBy(
    scorecardRequests.data,
    'reference.id'
  );
  return (
    <>
      <Card.Group itemsPerRow={2} stackable>
        {_.map(referenceScorecardRequests, (scorecards, referenceId) => {
          const reference = scorecards[0].reference; // all have the same reference
          return (
            <Card key={referenceId}>
              <Card.Content>
                <Card.Header>{reference.title}</Card.Header>
                <Card.Meta>
                  {
                    _.find(PAPER_TYPE_CHOICES, { value: reference.paper_type })
                      ?.text
                  }
                </Card.Meta>
                <Card.Meta
                  style={{
                    color:
                      reference.published_post_2010 === null
                        ? 'red'
                        : undefined,
                  }}
                >
                  Published{' '}
                  {reference.published_post_2010 === null
                    ? '???'
                    : reference.published_post_2010
                    ? 'post-2010'
                    : 'pre-2010'}
                </Card.Meta>
                <Card.Description>
                  <a href={reference.url} target="_blank" rel="noreferrer">
                    <Image inline src={reference.doi ? doiLogo : pubmedLogo} />{' '}
                    {reference.doi || reference.pmid}
                  </a>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Item.Group divided>
                  {scorecards.map(({ id, is_owned, protocol, feature }) => (
                    <Item key={id}>
                      <Item.Content>
                        {is_owned && (
                          <Button
                            icon="add"
                            content="Score"
                            positive
                            floated="right"
                            as={Link}
                            to={`${match.url}/${id}`}
                          />
                        )}
                        <Item.Header content={feature.name} />
                        <Item.Meta content={protocol.name} />
                      </Item.Content>
                    </Item>
                  ))}
                </Item.Group>
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>

      <Route path={`${match.path}/:requestId`}>
        <ScorecardAdd
          onClose={() => history.push(match.url)}
          onSave={() => history.replace(match.url)}
        />
      </Route>
    </>
  );
}

function ContributorProfileEdit({ onClose }) {
  const profile = useProfile();
  const [profileData, setProfileData] = useState(() => ({
    first_name: '',
    last_name: '',
  }));
  useEffect(() => {
    if (profile.data) {
      setProfileData(profile.data);
    }
  }, [profile.data]);

  const updateProfile = useUpdateProfile();

  const handleSubmit = () => {
    updateProfile.mutate(profileData, {
      onSuccess: onClose,
    });
  };

  const handleChange = (e, { name, value }) => {
    setProfileData((d) => ({ ...d, [name]: value }));
  };

  const togglePublic = () => {
    setProfileData((d) => ({ ...d, is_public: !d.is_public }));
  };

  return (
    <Segment secondary>
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            name="first_name"
            label="First name"
            value={profileData.first_name}
            onChange={handleChange}
          />
          <Form.Input
            name="last_name"
            label="Last name"
            value={profileData.last_name}
            onChange={handleChange}
          />
        </Form.Group>

        <Divider />

        <Form.Checkbox
          // slider
          label="If you leave this box checked, all your contributions will be public and your contributor profile will be listed in the contributors section."
          name="is_public"
          checked={profileData.is_public}
          onChange={togglePublic}
        />

        <Divider />

        <Form.Group inline>
          <Button content="Save" icon="save" primary />
          <Button type="button" content="Close" onClick={onClose} />
        </Form.Group>
      </Form>
    </Segment>
  );
}

function ContributorProfileView({ profile }) {
  const dateJoined = profile?.date_joined && new Date(profile?.date_joined);
  return (
    <Item.Group>
      <Item>
        <Item.Content>
          <Item.Header content={profile?.username} />
          <Item.Meta>Joined in {dateJoined?.toDateString()}</Item.Meta>
          <Item.Meta>
            Profile is{' '}
            <strong>{profile?.is_public ? 'public' : 'private'}</strong>
          </Item.Meta>
          <Item.Description>
            {profile?.first_name} {profile?.last_name}
          </Item.Description>
          {profile?.email && (
            <Item.Description>
              <Icon name="mail" /> {profile?.email}
            </Item.Description>
          )}
          <Item.Description content={profile?.description} />
        </Item.Content>
      </Item>
    </Item.Group>
  );
}

export function ContributorProfile({ profile }) {
  const { contributorId } = useParams();
  const match = useRouteMatch();

  const { data: currentProfile } = useProfile();

  const canEdit = !contributorId; // if no contributorId in url, means we are looking at self
  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <Header as="h2" className="peers-blue">
        {contributorId ? 'Contributor details' : 'Current contributor'}
      </Header>

      {editMode ? (
        <ContributorProfileEdit onClose={() => setEditMode(false)} />
      ) : (
        <Segment basic>
          <ContributorProfileView profile={profile} />
          {canEdit && (
            <>
              <Button
                content="Edit profile details"
                icon="edit"
                onClick={() => setEditMode(true)}
              />
              <Button
                content="Change password"
                icon="key"
                as={Link}
                to={`${match.url}/password`}
              />
            </>
          )}
        </Segment>
      )}

      <Menu secondary pointing>
        <Menu.Item as={NavLink} to={`${match.url}/scorecards`}>
          Scorecards
        </Menu.Item>
        {(!contributorId || currentProfile?.is_coordinator) && (
          <Menu.Item as={NavLink} to={`${match.url}/requests`}>
            Requests
          </Menu.Item>
        )}
      </Menu>

      <Switch>
        <Route path={`${match.path}/scorecards`}>
          <ContributorScorecards profile={profile} />
        </Route>
        <Route path={`${match.path}/requests`}>
          <ContributorRequests profile={profile} />
        </Route>
        <Route path={`${match.path}/password`}>
          <PasswordChangeForm />
        </Route>
        <Route>
          <Redirect to={`${match.url}/scorecards`} />
        </Route>
      </Switch>
    </>
  );
}

function ContributorView() {
  const { contributorId } = useParams();

  const { data: contributor } = useContributor(contributorId);
  return <ContributorProfile profile={contributor} />;
}

export function Contributors() {
  const match = useRouteMatch();

  const contributors = useContributors();
  return (
    <Switch>
      <Route path={`${match.path}/:contributorId(\\d+)`}>
        <ContributorView />
      </Route>
      <Route>
        <Header as="h2" className="peers-blue">
          Contributors
        </Header>
        <Card.Group itemsPerRow={3} stackable>
          {_.map(contributors.data, (contributor) => (
            <ContributorCard
              key={contributor.id}
              contributor={contributor}
              viewUrl={`${match.url}/${contributor.id}`}
            />
          ))}
        </Card.Group>
      </Route>
    </Switch>
  );
}

import { useState, useMemo, useEffect } from 'react';
import { Route, useRouteMatch, useHistory, useParams } from 'react-router-dom';

import { Tab, Modal, Button, Form, Loader, Divider } from 'semantic-ui-react';

import { Scorecard, ScorecardAdd, ScorecardTable } from './Scorecard';
import { ReferenceForm, Reference } from './Reference'
import { ParameterAddForm } from './Parameter';

import { useProfile } from './queries/profile';
import { useAddEvidence, useAddLiteratureEvidence, useDeleteEvidence, useEvidence } from './queries/evidence';
import { useAddReference, useAvailableReferences } from './queries/references';
import { useProtocol, useProtocols } from './queries/protocols';
import { useParameter } from './queries/parameters';

import _ from 'lodash';


export const LiteratureEvidenceAdd = ({ onAdded, onClose }) => {
  const { referenceId } = useParams();

  const [protocol, setProtocol] = useState(null);
  const protocols = useProtocols({ for_reference: referenceId });

  // auto select the one and only available Protocol
  useEffect(() => {
    if (protocol === null && protocols.data?.length === 1) {
      setProtocol(protocols.data[0].id);
    }
  }, [protocol, protocols.data]);

  const options = _.map(protocols.data, (p) => ({
    key: p.id, value: p.id, text: p.name
  }));

  return (
    <Modal centered={false} dimmer="inverted" size="small" open onClose={onClose}>
      <Modal.Header>Add Factor</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown label="Choose a Protocol:" selection
            options={options}
            value={protocol}
            onChange={(e, { value }) => setProtocol(value)}
          />
        </Form>
        <Divider hidden />
        { protocol && <LiteratureProtocolEvidenceAdd protocol={protocol} onAdded={onAdded} /> }
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} content="Cancel" />
        <Button form="createEvidenceForm" primary icon="save" content="Save" />
      </Modal.Actions>
    </Modal>
  );
};


export const LiteratureProtocolEvidenceAdd = ({ protocol, onAdded, modal, onClose }) => {
  const { protocolId=String(protocol), referenceId } = useParams();

  const addEvidence = useAddLiteratureEvidence({ protocol: protocolId, reference: referenceId });
  const handleSave = (data) => addEvidence.mutate(data, {
    onSuccess: onAdded,
  });

  const [readyToSave, setReadyToSave] = useState(false);

  const parameterForm = (
    <ParameterAddForm id="createEvidenceForm"
      onSave={handleSave}
      error={addEvidence.isError}
      onReadyChange={(ready) => setReadyToSave(ready)}
    />
  );

  if (!modal) return parameterForm;

  return (
    <Modal centered={false} dimmer="inverted" size="small" open onClose={onClose}>
      <Modal.Header>Add Factor</Modal.Header>
      <Modal.Content>
        <Loader disabled={!addEvidence.isLoading} />
        { parameterForm }
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} content="Cancel" />
        <Button form="createEvidenceForm" primary icon="save" content="Save" disabled={!readyToSave} />
      </Modal.Actions>
    </Modal>
  );
};


export const EvidenceAdd = ({ onClose }) => {
  const { parameterId } = useParams();
  const history = useHistory();
  const match = useRouteMatch();

  const references = useAvailableReferences(parameterId);
  const referenceOptions = useMemo(() => _.map(references.data, ({ id, title, doi }) => ({
    key: id,
    value: String(id),
    text: `${title} (${doi})`,
  })), [references.data]);

  const [selectedReferenceId, setSelectedReferenceId] = useState();
  const handleReferenceSelect = (e, { value }) => {
    setSelectedReferenceId(value);
  };

  const addEvidence = useAddEvidence(parameterId);
  const handleAddEvidence = (referenceId) => {
    if (!referenceId) return;

    addEvidence.mutate(referenceId, {
      onSuccess: (data) => {
        history.replace(match.url.replace(/new$/, data.id));
      },
    });
  };

  const [referenceData, setReferenceData] = useState(() => ({
    doi: '',
    title: '',
    url: '',
    year_published: null,
    paper_type: 'ORG',
  }));
  const addReference = useAddReference();

  const errorList = _.flatMap(addReference.error?.response.data, (errors) => errors);
  errorList.concat(_.flatMap(addEvidence.error?.response.data, (errors) => errors))

  const handleCreateReference = () =>
    addReference.mutate(referenceData, {
      onSuccess: (data) => handleAddEvidence(data.id)
    });

  // default is select from list
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleTabChange = (e, {activeIndex}) => setSelectedTabIndex(activeIndex);

  return (
    <Modal centered={false} dimmer="inverted" open onClose={onClose}>
      <Modal.Header>Reference</Modal.Header>
      <Modal.Content >
        <Tab renderActiveOnly={false} activeIndex={selectedTabIndex} onTabChange={handleTabChange}
          panes={[
            {
              key: 'select',
              menuItem: 'Select a Reference',
              pane: (
                <Tab.Pane>
                  <Form id="addEvidenceForm" onSubmit={() => handleAddEvidence(selectedReferenceId)}>
                    <Form.Dropdown selection search fluid
                      value={selectedReferenceId}
                      onChange={handleReferenceSelect}
                      options={referenceOptions}
                    />
                  </Form>
                </Tab.Pane>
              )
            },
            {
              key: 'create',
              menuItem: 'Create new Reference',
              pane: (
                <Tab.Pane>
                  <ReferenceForm id="createReferenceForm" data={referenceData} onChange={setReferenceData}
                    onSubmit={handleCreateReference}
                    errorList={errorList} error={addReference.isError || addEvidence.isError}
                  />
                </Tab.Pane>
              )
            },
          ]}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} content="Cancel" />
        <Button primary icon="save" content="Add"
          form={selectedTabIndex === 0 ? 'addEvidenceForm' : 'createReferenceForm' }
        />
      </Modal.Actions>
    </Modal>
  );
}

export const Evidence = ({ onClose, onRemoved }) => {
  const { parameterId, evidenceId } = useParams();

  const history = useHistory();
  const match = useRouteMatch();

  const { data: profile } = useProfile();
  const evidence = useEvidence(evidenceId);

  const deleteEvidence = useDeleteEvidence(parameterId);
  const handleRemove = () => {
    deleteEvidence.mutate(evidenceId, {
      onSuccess: onRemoved,
      onError: (err) => {
        alert(JSON.stringify(err));
      }
    });
  };

  const canRemove = evidence.data?.scorecards_count === 0 && (evidence.data?.is_owned || profile?.is_staff);
  // TODO update this when API responses become simpler
  const { id: evidenceReferenceId } = evidence.data?.reference || {};

  const parameter = useParameter(parameterId);
  const protocol = useProtocol(String(parameter.data?.protocol), { enabled: parameter.isFetched });

  const handleDisposeScorecardModal = () => history.replace(match.url);

  return (<>
    <Modal centered={false} dimmer="inverted" open onClose={onClose}>
      <Modal.Header>Reference for <i>{parameter.data?.feature.name}</i> in <i>{protocol.data?.name}</i></Modal.Header>
      <Modal.Content>
        <Reference referenceId={evidenceReferenceId} />
        <Divider hidden />

        <ScorecardTable />

        <Divider fitted hidden clearing />

      </Modal.Content>
      <Modal.Actions>
        { canRemove && <Button floated="left" negative icon="trash" content="Remove" onClick={handleRemove} /> }
        <Button onClick={onClose} content={evidenceId === 'new' ? 'Cancel' : 'Close'} />
      </Modal.Actions>
    </Modal>

    <Route path={`${match.path}/scorecards/:scorecardId(\\d+)`}>
      <Scorecard onClose={() => history.push(match.url)} onRemoved={handleDisposeScorecardModal} />
    </Route>

    <Route path={`${match.path}/scorecards/new`}>
      <ScorecardAdd onClose={handleDisposeScorecardModal} onSave={handleDisposeScorecardModal} />
    </Route>

  </>);

};

import { useEffect, useState, useMemo, useCallback } from 'react';
import { Route, Link, useRouteMatch, useHistory, useParams, useLocation } from 'react-router-dom';

import {
  Modal,
  Header,
  Form,
  Table,
  Button,
  Icon,
  Popup,
  Input,
  Divider,
  Search,
  Label,
  Accordion,
} from 'semantic-ui-react';

import MutationFormField from './MutationFormField';
import { Evidence, EvidenceAdd } from './Evidence';
import { PAPER_TYPE_CHOICES } from './Reference';

import { useProfile } from './queries/profile';
import { useProtocol } from './queries/protocols';
import { useAddParameter, useDeleteParameter, useParameter, useProtocolParameters, useUpdateParameter } from './queries/parameters';
import { useFeature, useFeatureCategories, useSearchFeatures } from './queries/features';
import { useParameterEvidence } from './queries/evidence';

import { useDebounce } from './hooks/useDebounce';

import _ from 'lodash';
import qs from 'qs';


export const colorForScore = (score) => {
  if (score?.avg) score = score.avg;
  if (_.isNil(score)) return undefined;

  score = Math.abs(score);
  
  if (score < 7) return 'red';
  if (score < 14) return 'orange';
  return 'green';
}


export const ScoreElement = ({ count, avg, ci_lower, ci_upper, popupPosition='left center' }) => {
  const trigger = <Table.Cell textAlign="center" content={count < 2 ? '-' : avg.toFixed(2)} />
  if (count >= 2 && (_.isNil(ci_lower) || _.isNil(ci_upper))) {
    return trigger;
  }

  const popupContent = count < 2 ? 'Not enough contributors have provided Scorecards for this Reference' : `Confidence Interval: [${ci_lower.toFixed(2)}, ${ci_upper.toFixed(2)}]`
  return <Popup trigger={trigger} content={popupContent} position={popupPosition} />;
}


export const ParameterAddForm = ({
  defaultFeatureName,
  defaultFeature,
  protocol,
  showRange,
  onSave,
  onReadyChange,
  ...props
}) => {
  const [range, setRange] = useState('');

  const [searchTerm, setSearchTerm] = useState(() => defaultFeature?.title || '');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const searchFeatures = useSearchFeatures({ protocol, search: debouncedSearchTerm });

  const featureCategories = useFeatureCategories();
  const [categorySearchTerm, setCategorySearchTerm] = useState('');
  const featureCategoryResults = useMemo(() => {
    const searchString = categorySearchTerm.toLowerCase();
    return _.map(
      _.filter(featureCategories.data, ({ name }) => name.toLowerCase().includes(searchString)),
      (c) => ({...c, title: c.name })
    );
  }, [featureCategories.data, categorySearchTerm]);

  const [showAddCategory, setShowAddCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const handleShowAddCategory = () => {
    setNewCategoryName(categorySearchTerm);
    setCategorySearchTerm('');
    setShowAddCategory(true);
  };
  const handleHideAddCategory = () => {
    setNewCategoryName('');
    setCategorySearchTerm('');
    setShowAddCategory(false);
  };


  const [showAddFeature, setShowAddFeature] = useState(() => defaultFeatureName);
  const [newFeatureName, setNewFeatureName] = useState(() => defaultFeatureName || '');
  const handleShowAddFeature = () => {
    setNewFeatureName(searchTerm);
    setSearchTerm('');
    setShowAddFeature(true);
  };
  const handleHideAddFeature = () => {
    setNewFeatureName('');
    setSearchTerm('');
    setShowAddFeature(false);
  };

  const [selectedCategory, setSelectedCategory] = useState(() => defaultFeature?.category || null);
  const handleSelectCategory = (e, { result }) => {
    setCategorySearchTerm(result?.title || '');
    setSelectedCategory(result || null);
  };

  const [selectedFeature, setSelectedFeature] = useState(() => defaultFeature || null);
  const handleSelectFeature = (e, { result }) => {
    setSearchTerm(result?.title || '')
    setSelectedCategory(result?.category || null);
    setSelectedFeature(result || null);
  };

  useEffect(() => {
    onReadyChange(
      selectedFeature ||
        (newFeatureName && (selectedCategory || newCategoryName))
    );
  }, [
    onReadyChange,
    selectedFeature,
    newFeatureName,
    selectedCategory,
    newCategoryName,
  ]);

  const searchResults = _.mapValues(
    _.groupBy(
      _.map(searchFeatures.data, (f) => ({ ...f, title: f.name, description: '\u00A0' })),
      'category.name'
    ),
    (results, name) => ({ name, results })
  );

  const handleSubmit = () => {
    onSave({
      feature: selectedFeature?.id,
      customFeature: newFeatureName,
      category: selectedCategory?.id,
      customCategory: newCategoryName,
      range,
    });
  };

  return (
    <Form {...props} onSubmit={handleSubmit}>
      <Form.Field required>
        <label>{ showAddFeature ? 'Custom Factor' : 'Factor'}</label>
        { selectedFeature ?
            <Input readOnly value={`${selectedFeature.name} (${selectedFeature.category.name})`}
              action={{ icon: 'cancel', size: props.size, title: 'Reset selection', onClick: () => handleSelectFeature(null, { result: null }) }}
            />
          : showAddFeature ?
            <Input value={newFeatureName} onChange={(e, { value }) => setNewFeatureName(value)}
              action={{ icon: 'cancel', size: props.size, onClick: handleHideAddFeature }}
            />
          : <Search
              input={{
                iconPosition: 'left',
                action: { icon: 'plus', size: props.size, positive: true, title: 'Add custom Factor', onClick: handleShowAddFeature }
              }}
              loading={searchFeatures.isLoading}
              showNoResults={false}
              minCharacters={0} placeholder="Type to locate the Factor to select"
              value={searchTerm}
              onSearchChange={(e, { value }) => setSearchTerm(value)}
              category results={searchResults}
              onResultSelect={handleSelectFeature}
            />
        }
      </Form.Field>

      { showAddFeature && 
        <Form.Field required>
          <label>{ showAddCategory ? 'Custom category' : 'Category for custom Factor' }</label>
          { selectedCategory ?
              <Input readOnly value={selectedCategory?.name}
                action={{ icon: 'cancel', size: props.size, title: 'Reset selection', onClick: () => handleSelectCategory(null, { result: null }) }}
              />
            : showAddCategory ?
              <Input value={newCategoryName} onChange={(e, { value }) => setNewCategoryName(value)}
                action={{ icon: 'cancel', size: props.size, onClick: handleHideAddCategory }}
              />
            : <Search
                input={{
                  iconPosition: 'left',
                  action: { icon: 'plus', size: props.size, positive: true, title: 'Custom category', onClick: handleShowAddCategory }
                }}
                loading={featureCategories.isLoading}
                showNoResults={false}
                minCharacters={0} placeholder="Type to locate the category to select"
                value={categorySearchTerm}
                onSearchChange={(e, { value }) => setCategorySearchTerm(value)}
                results={featureCategoryResults}
                onResultSelect={handleSelectCategory}
              />
          }
        </Form.Field>
      }

      { showRange && <Form.Input
        disabled={!(selectedFeature || (newFeatureName && (selectedCategory || newCategoryName)))}
        name="range" label="Range" value={range} onChange={(e, { value }) => setRange(value)} />
      }

    </Form>
  );
};


export const ParameterAdd = ({ modal=false, size, onClose, onAdded }) => {
  const { protocolId } = useParams();
  const { search } = useLocation();
  const { featureName, featureId } = qs.parse(search, { ignoreQueryPrefix: true });

  const [readyToSave, setReadyToSave] = useState(false);

  const addParameter = useAddParameter(protocolId);
  const handleSave = (data) => addParameter.mutate(data, {
    onSuccess: onAdded,
  });

  const { data: defaultFeature, isLoading } = useFeature(featureId);
  if (isLoading) return null; // wait for it...

  const parameterAddForm = (
    <ParameterAddForm
      id="createParameterForm"
      size={size}
      protocol={protocolId}
      defaultFeatureName={featureName}
      defaultFeature={defaultFeature}
      onSave={handleSave}
      onReadyChange={(ready) => setReadyToSave(ready)}
      error={addParameter.isError}
    />
  );

  // TODO figure out if we want to keep this optional modal business
  if (!modal) return parameterAddForm;

  return (
    <Modal centered={false} dimmer="inverted" size="small" open onClose={onClose}>
      <Modal.Header>Add Factor</Modal.Header>
      <Modal.Content>
        { parameterAddForm }
      </Modal.Content>
      <Modal.Actions>
        <Button size={size} onClick={onClose} content="Cancel" />
        <Button size={size} form="createParameterForm" primary icon="save" content="Save" disabled={!readyToSave} />
      </Modal.Actions>
    </Modal>
  );
};

const ParameterView = ({ showRange, onClose }) => {
  const { parameterId } = useParams();
  const match = useRouteMatch();

  const { data: profile } = useProfile();
  const parameter = useParameter(parameterId);

  // form data state
  const [parameterData, setParameterData] = useState(() => ({ range: '' }));
  useEffect(() => {
    if (parameter.data) {
      setParameterData(parameter.data);
    }
  }, [parameter.data]);

  const handleChange = (e, {name, value}) => {
    setParameterData((data) => ({ ...data, [name]: value }));
  };

  // TODO remove these if parameters indeed will not have additional properties like range
  const updateParameter = useUpdateParameter(parameterId);
  const handleSubmit = (e) => updateParameter.mutate(parameterData, {
    onSuccess: () => {
      if (e.type === 'click') {
        // means we want to close the modal also
        onClose?.();
      }
      // reset after a while
      setTimeout(updateParameter.reset, 1000);
    }
  });

  const toggleApprove = useCallback(() => {
    updateParameter.mutate({ is_public: !parameterData.is_public });
  }, [updateParameter, parameterData.is_public]);

  const canModify = parameter.data?.is_owned || profile?.is_staff;
  const canApprove = profile?.is_coordinator && !parameter.data?.feature.is_generic;

  const evidence = useParameterEvidence(parameterId);

  const scorecardsCount = _.reduce(evidence.data, (count, { score }) => count + score.count, 0);

  const [showReferences, setShowReferences] = useState(false);

  return (
    <>
      <Header dividing as="h3">{parameter.data?.feature.name}
        <Header.Subheader>{parameter.data?.feature.category.name}</Header.Subheader>
      </Header> 

      { showRange && <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <MutationFormField
            mutation={updateParameter}
            label="Range" action="Update range"
            control={Input} name="range" value={parameterData.range} onChange={handleChange} readOnly={!canModify}
          />
        </Form.Group>
      </Form> }

      { canApprove && <div style={{ float: 'right' }}>
          <Form.Checkbox
            label="Approved"
            slider
            checked={parameterData.is_public}
            onChange={toggleApprove}
          />
        </div>
      }

      <Label.Group size="large">
        <ParameterLabel content="Total score" parameter={parameter.data} /> 
        <Label color="grey" content="References" detail={evidence.data?.length} />
        <Label color="grey" content="Scorecards" detail={scorecardsCount} />
      </Label.Group>

      <Divider hidden clearing />

      {evidence.data?.length > 0 && (
        <Accordion>
          <Accordion.Title
            active={showReferences}
            onClick={() => setShowReferences((p) => !p)}
            icon="dropdown"
            content="Show details"
          />
          <Accordion.Content active={showReferences}>
            <Header as="h4">References</Header>
            <Table structured selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={2}>Title</Table.HeaderCell>
                  <Table.HeaderCell>Paper type</Table.HeaderCell>
                  <Table.HeaderCell>Score</Table.HeaderCell>
                  <Table.HeaderCell>Scorers</Table.HeaderCell>
                  <Table.HeaderCell collapsing />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { _.map(evidence.data, ({ id, is_owned, score, reference: { title, url, paper_type } }) => 
                    <Table.Row key={id}>
                      <Table.Cell collapsing>
                        { is_owned && <Popup trigger={<Icon name="user" color="green" />} content="Contributed by current user" position="top center" /> }
                      </Table.Cell>
                      <Table.Cell><a title={url} href={url} target="_blank" rel="noreferrer">{title}</a></Table.Cell>
                      <Table.Cell>{_.find(PAPER_TYPE_CHOICES, { value:  paper_type })?.text}</Table.Cell>
                      <ScoreElement {...score} />
                      <Table.Cell textAlign="right">{score.count}</Table.Cell>
                      <Table.Cell collapsing>
                        <Button as={Link} to={`${match.url}/evidence/${id}`} icon="info" primary title="Details" />
                      </Table.Cell>
                    </Table.Row>
                  )
                }
              </Table.Body>
            </Table>
          </Accordion.Content>
        </Accordion>
      )}

      { profile && <>
          <Button floated="right" as={Link} to={`${match.url}/evidence/new`} positive icon="plus" content="Add Reference" />
          <Divider fitted hidden clearing />
      </>}

    </>
  );
}


const Parameter = ({ returnTo, onClose, onRemoved }) => {
  const { protocolId, parameterId } = useParams();

  const history = useHistory();
  const match = useRouteMatch();

  const parameter = useParameter(parameterId);
  const { data: profile } = useProfile();

  const deleteParameter = useDeleteParameter(protocolId);
  const handleRemove = () => deleteParameter.mutate(parameterId, {
    onSuccess: onRemoved,
    onError: (err) => {
      alert(JSON.stringify(err));
    },
  });

  const canRemove = parameter.data?.evidence_count === 0 && (parameter.data?.is_owned || profile?.is_staff);
  const protocol = useProtocol(protocolId);

  return (<>
    <Modal centered={false} dimmer="inverted" size={parameterId === 'new' ? 'small': 'large'} open onClose={onClose}>
      <Modal.Header>Factor in <i>{protocol.data?.name}</i></Modal.Header>
      <Modal.Content>
        <ParameterView onClose={onClose} />
      </Modal.Content>
      <Modal.Actions>
        { canRemove && <Button floated="left" negative icon="trash" content="Remove" onClick={handleRemove} /> }
        <Button onClick={onClose} content={returnTo ? 'Show Protocol' : 'Close'} />
        { returnTo && <Button primary as={Link} to={returnTo} content="Return to explore" /> }
      </Modal.Actions>
    </Modal>

    <Route path={`${match.path}/evidence/:evidenceId(\\d+)`}>
      <Evidence onClose={() => history.push(match.url)} onRemoved={() => history.replace(match.url)} />
    </Route>

    <Route path={`${match.path}/evidence/new`}>
      <EvidenceAdd onClose={() => history.replace(match.url)} />
    </Route>

  </>);

};

export const ParameterLabel = ({
  parameter: {
    id,
    feature: { name: featureName}={},
    protocol,
    score,
    references,
  }={},
  linkTo,
  content,
  ...props
}) => {
  const pendingLabel =
    references && _.size(references) < 2
      ? 'References needed'
      : score === null && 'Scorecards needed';

  if (pendingLabel) content = undefined;

  const scoreLabel = score ? score?.avg.toFixed(2) : pendingLabel;

  return (
    <Label
      {...props}
      content={linkTo ? featureName : (content || scoreLabel)}
      detail={(content || linkTo) ? scoreLabel : undefined}
      color={colorForScore(score)}
      as={linkTo ? Link : undefined}
      to={linkTo ? { pathname: `/protocols/${protocol}/parameters/${id}`, ...linkTo } : undefined}
    />
  );
};


const ParameterEvidenceRow = ({ showPending, showCategory=true, showScore=true, showRange=false, ...parameter }) => {
  const match = useRouteMatch();

  const{ id, is_owned, is_public, references, feature: {name: featureName, category: { name: categoryName }}, range, score } = parameter;

  const pending =
    _.size(references) < 2
    ? 'references'
    : (score === null && 'ratings')
  ;

  return (
    <Table.Row>
      <Table.Cell>
        { showCategory ? featureName : <Popup mouseEnterDelay={500} trigger={<span>{featureName}</span>} content={categoryName} /> }
      </Table.Cell>
      <Table.Cell collapsing textAlign="right">
        { !is_public && <Popup trigger={<Icon color="grey" name="eye slash" />} content="Unapproved Factor" position="top center" /> }
        { is_owned && <Popup trigger={<Icon color="green" name="user" />} content="Contributed by current user" position="top center" /> }
      </Table.Cell>
      { showCategory && (
        <Table.Cell>{categoryName}</Table.Cell>
      )}

      { showRange && <Table.Cell>{range}</Table.Cell> }

      { showScore && 
        <Popup position="left center"
          trigger={<Table.Cell textAlign="right" content={<ParameterLabel parameter={parameter} />} />}
          content={score ? `Confidence Interval: [${score.ci_lower.toFixed(2)}, ${score.ci_upper.toFixed(2)}]` : 'Not enough contributors have provided scores for this Factor'}
        />
      }

      { showPending && 
        <Table.Cell collapsing>
          { pending && <Label content="Required:" detail={pending}/> }
        </Table.Cell>
      }

      <Table.Cell collapsing>
        <Button as={Link} to={`${match.url}/parameters/${id}`} icon="info" primary title="Details" />
      </Table.Cell>
    </Table.Row>
  );
};

export const ParameterEvidenceTable = ({ parameters, ...props }) => {
  const { showRange=false, showCategory=true, showScore=true, showPending=false } = props;

  const [columnSorted, setColumnSorted] = useState(() => showPending ? { factor: 'ascending' } : { score: 'descending' });

  const handleSwitchSorting = (column) => {
    setColumnSorted((columnSorted) => {
      const sorted = columnSorted[column];
      if (sorted === 'ascending') {
        return {[column]: 'descending'};
      } else {
        // is undefined
        return {[column]: 'ascending'};
      }
    });
  };

  const [sortColumns, sortOrders] = useMemo(() => {
    const columns = [], orders = [];
    if (columnSorted.score) {
      columns.push(({score}) => (score?.avg ?? 0));
      if (columnSorted.score === 'descending') {
        orders.push('desc');
      } else {
        orders.push('asc');
      }
    }

    if (columnSorted.factor) {
      columns.push('feature.name');
      if (columnSorted.factor === 'descending') {
        orders.push('desc');
      } else {
        orders.push('asc');
      }
    }

    if (columnSorted.category) {
      columns.push('feature.category.name');
      if (columnSorted.category === 'descending') {
        orders.push('desc');
      } else {
        orders.push('asc');
      }
    }

    return [columns, orders];
  }, [columnSorted]);

  parameters = _.orderBy(parameters, sortColumns, sortOrders);

  return (
    <Table sortable selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2} sorted={columnSorted.factor || null} onClick={() => handleSwitchSorting('factor')}>Factor</Table.HeaderCell>
          { showCategory && <Table.HeaderCell sorted={columnSorted.category || null} onClick={() => handleSwitchSorting('category')}>Category</Table.HeaderCell> }
          { showRange && <Table.HeaderCell>Range</Table.HeaderCell> }
          { showScore && <Table.HeaderCell collapsing sorted={columnSorted.score || null} onClick={() => handleSwitchSorting('score')}>Total score</Table.HeaderCell> }
          <Table.HeaderCell collapsing disabled colSpan={showPending ? 2 : 1} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { _.map(parameters, (parameter) =>
            <ParameterEvidenceRow key={parameter.id} {...parameter} {...props} />
        ) }
      </Table.Body>

    </Table>
  );
};

const ParameterTableRow = ({ id, rowSpan, showPending, showScore=true, showRange=false, is_owned, references, feature: {name: feature_name, category: { name: category_name }}, range, score }) => {
  const match = useRouteMatch();

  const pending =
    _.size(references) < 2
    ? 'references'
    : (score === null && 'ratings')
  ;

  return (
    <Table.Row>
      { rowSpan ? <Table.Cell collapsing active rowSpan={rowSpan}><Header as="h4">{ category_name }</Header></Table.Cell> : null }
      <Table.Cell collapsing>{ is_owned && <Popup trigger={<Icon color="green" name="user" />} content="Contributed by current user" position="top center" /> }</Table.Cell>
      <Table.Cell>{feature_name}</Table.Cell>
      { showRange && <Table.Cell>{range}</Table.Cell> }

      { showScore && 
        <Popup position="left center"
          trigger={<Table.Cell content={score ? score.avg.toFixed(2) : '-'} />}
          content={score ? `Confidence Interval: [${score.ci_lower.toFixed(2)}, ${score.ci_upper.toFixed(2)}]` : 'Not enough contributors have provided scores for this Factor'}
        />
      }

      { showPending && 
        <Table.Cell collapsing>
          { pending && <Label content="Required:" detail={pending}/> }
        </Table.Cell>
      }

      <Table.Cell collapsing>
        <Button as={Link} to={`${match.url}/parameters/${id}`} icon="info" primary title="Details" />
      </Table.Cell>
    </Table.Row>
  );
};

const ParameterTableCategory = ({ rowSpan, parameters, ...props }) => {
  return (
    <>
      <ParameterTableRow {...parameters[0]} rowSpan={parameters.length} {...props} />
      { parameters.slice(1).map((p) => <ParameterTableRow key={p.id} {...p} {...props} />) }
    </>
  );
}

// eslint-disable-next-line no-unused-vars
const ParameterTable = ({ parameters, ...props }) => {
  const { showRange=false, showScore=true, showPending=false } = props;
  const categories = _.groupBy(parameters, 'feature.category.name');

  return (
    <Table structured selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell colSpan={2}>Factor</Table.HeaderCell>
          { showRange && <Table.HeaderCell>Range</Table.HeaderCell> }
          { showScore && <Table.HeaderCell collapsing>Total score</Table.HeaderCell> }
          { showPending && <Table.HeaderCell collapsing /> }
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { _.map(categories, (parameters, category) =>
            <ParameterTableCategory key={category} 
              parameters={parameters}
              rowSpan={parameters.length}
              {...props}
            />
        ) }
      </Table.Body>

    </Table>
  );
};

const ParameterScoresLegend = () => {
  return (
    <Table basic celled={false}>
      <Table.Body>
        <Table.Row>
          <Table.Cell><Button fluid compact color={colorForScore(20)} content="14-20" /></Table.Cell>
          <Table.Cell>
            The quality of the existing evidence strongly supports the finding
            that the selected Factor either does or does not influence the
            selected Protocol.
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><Button fluid compact color={colorForScore(13)} content="7-13" /></Table.Cell>
          <Table.Cell>
            The quality of the existing evidence supports the finding that the
            selected Factor either does or does not influence the selected
            Protocol.
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell><Button fluid compact color={colorForScore(6)} content="0-6" /></Table.Cell>
          <Table.Cell>
            The quality of the existing evidence is not sufficient to
            unambiguously determine whether this Factor does or does not
            influence the selected Protocol.
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export const ParametersView = () => {
  const { protocolId } = useParams();

  const history = useHistory();
  const match = useRouteMatch();

  const parameters = useProtocolParameters({ protocol: protocolId});

  // we want to split this up in three:
  // 1) the ones with sufficient positive scores
  const parametersAffecting = _.filter(parameters.data, (p) => p.score?.avg > 0);
  // 2) the ones with sufficient negative scores
  const parametersNotAffecting = _.filter(parameters.data, (p) => p.score?.avg <= 0);
  // 3) the ones with unclear status, so no score
  const parametersUnclear = _.filter(parameters.data, (p) => p.score === null);

  const { state: { returnTo } = {} } = useLocation();
  const handleClose = () => history.push(match.url);

  return (<>
    <ParameterScoresLegend />

    { parametersAffecting.length > 0 && <>
      <Header dividing as="h3">Factors affecting the outcome</Header>
      <ParameterEvidenceTable parameters={parametersAffecting} />
    </> }
    { parametersNotAffecting.length > 0 && <>
      <Header dividing as="h3">Factors not affecting the outcome</Header>
      <ParameterEvidenceTable parameters={parametersNotAffecting} />
    </> }

    { (parametersUnclear.length > 0) && <>
      <Header dividing as="h3">Factors with unclear status</Header>
      <ParameterEvidenceTable parameters={parametersUnclear} showScore={false} showPending />
    </> }

    <Route path={`${match.path}/parameters/:parameterId(\\d+)`}>
      <Parameter returnTo={returnTo} onClose={handleClose} onRemoved={() => history.replace(match.url)} />
    </Route>
  </>);
};

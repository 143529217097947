import { Button, Form, Popup } from 'semantic-ui-react';

export default function MutationFormField({ mutation, action, errorMessage, ...props }) {
  const actionButton = props.readOnly ? undefined :
    <Popup on="click"
      open={mutation.isError} onClose={mutation.reset}
      content={errorMessage || mutation.error?.message}
      trigger={
      <Popup trigger={<Button
        icon={mutation.isSuccess ? 'check' : mutation.isError ? 'exclamation' : 'save'}
        positive={mutation.isSuccess}
        negative={mutation.isError}
        primary={!mutation.isSuccess && !mutation.isError}
        loading={mutation.isLoading}
      />} content={action} />}
    />;

  return (
    <Form.Field {...props} action={actionButton} />
  );
}

import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Modal, Message, Button } from 'semantic-ui-react';

import axios from 'axios';

export default function ActivateAccount() {
  const { uid, token } = useParams();

  const activate = useMutation(async () => {
    try {
      const { data } = await axios.post('/api/auth/users/activation/', {
        uid,
        token,
      });
      return data;
    } catch (error) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });

  // activate immediately
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => activate.mutate(), []);

  return (
    <Modal open size="small" dimmer="inverted" centered={false}>
      <Modal.Header content="Activate account" />
      <Modal.Content>
        {activate.isError && (
          <Message
            error
            header="Could not activate your account."
            content="Most probably it is already activated. Otherwise, please try again in a few minutes."
          />
        )}
        {activate.isSuccess && (
          <Message
            success
            header="Your account has been successfully activated!"
            content="You can now sign in using your email and password"
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {activate.isError && (
          <Button content="Repeat" onClick={() => activate.mutate()} />
        )}

        <Button
          primary
          content={activate.isSuccess ? 'OK' : 'Return'}
          as={Link}
          to="/"
          replace
        />
      </Modal.Actions>
    </Modal>
  );
}

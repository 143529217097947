import { Link } from 'react-router-dom';
import { Header, Item, Segment } from 'semantic-ui-react';

const questionItems = [
  {
    key: 1,
    question: 'Is PEERS free to use?',
    answer: <>Yes indeed – PEERS is an open-access platform!</>,
  },
  {
    key: 2,
    question: 'Is registration essential for creating a PEERS account?',
    answer: (
      <>
        Yes, registration is essential to be able to access the content and also
        contribute to the platform. However, registration is a really easy
        process needing only an email ID and password to activate (see below).
        All data is stored compliant to the GPDR laws. To register, click here.
      </>
    ),
  },
  {
    key: 3,
    question: 'Who can register for an PEERS account?',
    answer: (
      <>
        Registration is free and open to everyone, but contributors must accept
        the PEERS Code of Conduct to be able to contribute to the database.
      </>
    ),
  },
  {
    key: 4,
    question: 'How to register for a PEERS account?',
    answer: (
      <>
        Only a valid username, email address and password are needed to
        register.
      </>
    ),
  },
  {
    key: 5,
    question: 'Can I submit my research to PEERS?',
    answer: (
      <>
        Yes. PEERS provides a platform for the{' '}
        <Link to="/references">submission of published studies</Link>. In the
        near future, PEERS will also provide an input matrix for submitting
        unpublished research results.
      </>
    ),
  },
  {
    key: 6,
    question: 'How does PEERS work and how can I use it?',
    answer: [
      <>
        PEERS allows users to search for Factors and parameters that are of
        relevance to their experiments and gives guidance on how to leverage
        these Factors to increase the quality, robustness and reproducibility of
        their research. For more details, <Link to="/about">click here</Link>.
      </>,
      <>
        The Quality of Evidence that a certain Factor can indeed influence the
        outcome of the Protocol of interest, is assessed and summarized via the
        detailed PEERS “Scorecards”.
      </>,
      <>
        PEERS also provides the opportunity for registered users to add new
        Protocols, Factors or Scorecards - thereby expanding the PEERS database
        and our understanding of the importance of each potential experimental
        Factor.
      </>,
    ],
  },
  {
    key: 7,
    question: 'How can I contribute to PEERS?',
    answer: (
      <>
        Contributions can occur via many ways – users can either add a new
        Protocol, new Factor or new references and Scorecards for Factors
        belonging to one or multiple Protocols. Your contributions will be
        displayed alongside your username on the platform and will also be
        available to view via your profile.
      </>
    ),
  },
  {
    key: 8,
    question: 'Can I submit unpublished data?',
    answer: (
      <>
        While currently the database does not allow for the addition of
        unpublished data, we envision with the growth of the database, we will
        employ specific criteria to verify the strength of unpublished evidence
        (as this can be an important source of evidence) and feature this on the
        platform too.
      </>
    ),
  },
  {
    key: 9,
    question: 'How can I share or cite my contributions?',
    answer: (
      <>
        You can click on CONTRIBUTORS at the bottom of each page to get a
        summary of your contributions and contributions added by other users (If
        they agreed that this information is publicly visible). All your
        personal contributions will also be available to view on your profile
        page. The website link is unique and specific to your contributions and
        can be used to share/cite your contributions.
      </>
    ),
  },
  {
    key: 10,
    question: 'Why will the PEERS database be useful to me?',
    answer: [
      <>
        PEERS provides scientists with a fast and efficient way to find key
        information for their models/methods of interest - including saving time
        to compile previous literature. By considering all critical Factors
        influencing the experimental outcome, the quality of conducted studies
        and the reproducibility rate will increase.
      </>,
      <>
        PEERS will help to induce a cultural change in the implementation of
        quality principles in life science research: Through dissemination and
        education, PEERS will drive the research community-wide expansion of
        knowledge on criteria and principles necessary for reproducibility and
        data/research quality.
      </>,
    ],
  },
  {
    key: 11,
    question: 'Can I trust the information on PEERS?',
    answer: (
      <>
        The PEERS Working Group currently consists of eminent neuroscientists
        with many years of experience with reproducibility and reliability
        research in preclinical neuroscience. They will oversee all submitted
        reviews (and be reviewers themselves) in the first instance to ensure
        reliability – but all references are also rated by at least two
        reviewers, and two or more references are required to generate the
        score. For the sake of transparency the PEERS database also presents all
        individual scores and the respective Scorecards are also stored and
        available on the platform. Going forward, for other biological
        disciplines, we will invite 2-3 international experts to form PEERS
        ‘Board of Editors’ for their respective disciplines. ‘Board of Editor’
        members will have the oversight of the respective field and ensure that
        only reasonable information is added to the PEERS database.
      </>
    ),
  },
  {
    key: 12,
    question: 'Can I share a PEERS “Report” with funders? ',
    answer: (
      <>
        PEERS is currently in the process of setting up collaborations with
        funders and indeed it is our ambition for users to be able to share
        these “Reports” (confirming compliance/agreement with PEERS guidance)
        with funders.
      </>
    ),
  },
  {
    key: 13,
    question:
      'What are PEERS “ScoreCards” and what criteria are used to evaluate Scorecards? ',
    answer: [
      <>
        To provide a structured template to determine the quality of each
        selected publication, so-called PEERS “ScoreCards” have been developed.
      </>,
      <>
        These Scorecards with their evidence rating system were refined through
        multiple Delphi rounds within the PEERS Working Group and contain a
        checklist with two main domains (Methods and Results):
      </>,
      <>
        All elements of the Methods domain were based on ARRIVE 2.0 “Essential
        10” and recommendations of the EQIPD consortium (Percie du Sert et al.,
        2020; Vollert et al., 2020). The Results domain aims at evaluating the
        quality and suitability of the published results and analyses.
      </>,
      <>
        For each domain, a maximum score of 10 is awarded, if all items are
        sufficiently addressed. The Scorecards constitute a unique feature of
        the PEERS database because not only do they evaluate reporting of the
        methods in any paper, but also take into account the suitability and
        strength of the results presented.
      </>,
      <>
        After adding multiple Scorecards for the same Factor to the database,
        PEERS then automatically calculates a score sum. If only one assessor
        has scored a single citation, the score of this citation is presented.
        Ideally, however, each citation is evaluated by two or more assessors to
        remove any source of bias. In this case, an average score of all
        Scorecards is presented, along with the calculated standard deviation,
        standard error and confidence intervals around the average score. This
        detailed information provides the user with a better understanding of
        the degree of consensus that the assessors reached evaluating the
        quality of the citation.
      </>,
    ],
  },
  {
    key: 14,
    question:
      'What should I do if I do not receive an email after creating a new account?',
    answer: (
      <>
        If you haven’t received a confirmation email, please check your spam
        folder and if after 24 hours you haven’t received anything, please get
        in touch at : -----
      </>
    ),
  },
  {
    key: 15,
    question: 'What are PEERS “Protocols”? ',
    answer: (
      <>
        The PEERS database contains a searchable list of various in vitro and in
        vivo Protocols or experimental methods which are of interest for
        scientists in the biomedical research area. This list of Protocols will
        be constantly expanded to cover as many scientific fields as possible.
      </>
    ),
  },
  {
    key: 16,
    question: 'What are PEERS “Factors”? ',
    answer: (
      <>
        The experimental “Factors” are the central element of the PEERS
        database. Factors are defined as any aspect of a study that can affect
        its outcome. Thus, information how to incorporate them into the study
        design (e.g., ignore/control, monitor, report) is required. Users of the
        platform can search for these Factors depending on the Protocol as well
        as outcomes they are interested in.
      </>
    ),
  },
  {
    key: 17,
    question:
      'Is the evidence based on peer-reviewed published information only? ',
    answer: [
      <>
        Currently the database is only based on peer-reviewed published
        information. However, a lot of practical knowledge about experimental
        parameters and Factors is acquired by students, postdocs, etc. over time
        when working on specific Protocols, but this knowledge and expertise is
        often not documented in publications and can easily be lost when
        scientists leave a research group or move on to work on different
        projects. Thus, we would like to capture this valuable knowledge in the
        PEERS database and protect it from being lost. This would strengthen and
        compliment the information currently provided by PEERS.
      </>,
      <>
        Thus, we will develop a set of specific criteria for unpublished
        evidence from experts to ensure proper management, quality control and
        utility of such unpublished information.
      </>,
    ],
  },
  {
    key: 18,
    question:
      'Is PEERS only focused on neuroscience or will it contain Protocols from other areas of research? ',
    answer: (
      <>
        The ambition is for the platform to also contain in vivo and in vitro
        Protocols from other areas of biomedical research such as cancer,
        cardiovascular research, immunology, microbiology, etc.
      </>
    ),
  },
  {
    key: 19,
    question: 'What does high, medium and low quality of evidence mean? ',
    answer: (
      <>
        The grading system is simplified to establish the overall scores for
        each Factor into high (&gt;14/20), medium (5-13/20), and low (&lt;5/20)
        quality (or no evidence). High evidence means there is high quality of
        published evidence from multiple references indicating that a specific
        Factor is involved/not involved in the conduct/design/analysis of the
        Protocol of choice.
      </>
    ),
  },
  {
    key: 20,
    question: 'How are the scores for each Factor calculated? ',
    answer: [
      <>
        After adding multiple Scorecards for the same Factor to the database,
        PEERS automatically calculates a score sum. If only one assessor has
        scored a single citation, the score of this citation is presented.
        Ideally, however, each citation is evaluated by two or more assessors to
        remove any source of bias. In this case, an average score of all
        Scorecards is presented, along with the calculated standard deviation,
        standard error and confidence intervals around the average score. This
        detailed information provides the user with a better understanding of
        the degree of consensus that the assessors reached evaluating the
        quality of the citation.
      </>,
      <>
        Besides the number of assessors for each citation, the number of
        identified citations for each Factor needs to be considered when
        calculating the final score. When at least two assessors have scored two
        references, the system automatically starts a meta-analytic calculation
        process: a fixed-effect model is used and I2 test statistics are applied
        to evaluate the heterogenicity between assessors and citations. This
        tests both the consensus of the assessors for each citation as well as
        the consensus of the citation regarding the Factor of interest.
        Moreover, forest plots are generated with confidence intervals depicted,
        providing a bird’s eye view of all scores/Scorecards that exist in the
        PEERS database for any given Factor or any given Protocol.
      </>,
      <>
        The grading system is then simplified to establish the overall scores
        for each Factor into high (&gt;14/20), medium (5-13/20), and low
        (&lt;5/20) quality (or no evidence), which is displayed for users on the
        front-end of the platform.
      </>,
    ],
  },
  {
    key: 21,
    question:
      'Who scores the references and can I be a reviewer for a Reference?',
    answer: (
      <>
        Any scientists from the scientific community with knowledge in fields
        covered by PEERS can score references and can act as a reviewer after
        registration. To support and structure the review process PEERS provides
        detailed “ScoreCards”.
      </>
    ),
  },
  {
    key: 22,
    question:
      'Will I be notified if one of my authored publications is added onto the database? ',
    answer: (
      <>
        Yes as a registered user, if your publication is added to the database
        an email will be sent to you.
      </>
    ),
  },
];

export function FrequentQuestions() {
  return (
    <Segment size="large" basic>
      <Header as="h2" className="peers-blue">
        Frequently Asked Questions
      </Header>

      <Item.Group divided>
        {questionItems.map(({ key, question, answer }) => (
          <Item key={key}>
            <Item.Content>
              <Item.Header>
                {key}. {question}
              </Item.Header>
              <Item.Description>
                {answer.map ? answer.map((a) => <p>{a}</p>) : <p>{answer}</p>}
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </Segment>
  );
}

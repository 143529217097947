import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from 'semantic-ui-react';

import FeatureSearchBox from './components/FeatureSearchBox';

export function PeersLandingPage() {
  return (
    <Segment basic size="large">
      <Container>
        <Segment placeholder>
          <Header textAlign="center" size="huge">
            <em>
              Do you know all experimental Factors which can influence your
              results?
            </em>
          </Header>
        </Segment>

        <Segment className="peers-blue" textAlign="center" basic>
          <Button
            size="huge"
            content="Create a Free Account"
            positive
            as={Link}
            to="/register"
          />
        </Segment>

        <FeatureSearchBox />

        <Segment padded basic>
          <Segment basic size="huge" textAlign="center">
            <Header>PEERS is a community-driven Platform:</Header>
            <p>
              We invite experts from scientific community to provide input in
              form of new Protocols, Factors, or Scorecards.
            </p>
          </Segment>
          <Image centered size="huge"
            src={`${process.env.PUBLIC_URL}/peers_platform_overview.png`}
          />
          <List bulleted size="huge">
            <List.Item>
              As a community, we will generate the most complete list of Factors
              for all Protocols
            </List.Item>
            <List.Item>
              As an expert, you will get recognition for your contribution and
              for developing the most complete picture of your Protocol of
              interest
            </List.Item>
          </List>
        </Segment>

        <Divider hidden />

        <Card.Group itemsPerRow={3} stackable>
          <Card color="green">
            <Card.Content>
              <Card.Header>
                <Header icon="tasks" content="Protocols" />
              </Card.Header>
              <Card.Description>
                The PEERS database consists of various in vivo models and in
                vitro methods, which are termed Protocols.
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Card.Meta as={HashLink} to="/about#protocols">
                <span style={{ float: 'right' }}>
                  <Icon
                    link
                    size="large"
                    name="arrow alternate circle right outline"
                    floating="right"
                  />
                </span>
                Detailed Protocol Information
              </Card.Meta>
            </Card.Content>
          </Card>
          <Card color="green">
            <Card.Content>
              <Card.Header>
                <Header icon="setting" content="Factors" />
              </Card.Header>
              <Card.Description>
                The central elements of PEERS are called “Factors,” defined as
                any aspect of a study that can affect the outcome of a specific
                Protocol.
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Card.Meta as={HashLink} to="/about#factors">
                <span style={{ float: 'right' }}>
                  <Icon
                    link
                    size="large"
                    name="arrow alternate circle right outline"
                    floating="right"
                  />
                </span>
                Detailed Factor Information
              </Card.Meta>
            </Card.Content>
          </Card>
          <Card color="green">
            <Card.Content>
              <Card.Header>
                <Header icon="newspaper outline" content="Scorecards" />
              </Card.Header>
              <Card.Description>
                The Quality of Evidence for the importance of certain Factors is
                evaluated using the PEERS Scorecards.
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Card.Meta as={HashLink} to="/about#scorecards">
                <span style={{ float: 'right' }}>
                  <Icon
                    link
                    size="large"
                    name="arrow alternate circle right outline"
                    floating="right"
                  />
                </span>
                Detailed Scorecards Information
              </Card.Meta>
            </Card.Content>
          </Card>
        </Card.Group>

        <Divider section />

        <Grid columns={2} padded>
          <Grid.Column>
            <Segment>
              <Header dividing textAlign="center">
                Explore the PEERS database
              </Header>
              <Grid padded>
                <Grid.Row as={Link} to="/protocols?type=VIV">
                  <Grid.Column width={2}>
                    <Icon name="paw" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>In vivo Protocols</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row as={Link} to="/protocols?type=VIT">
                  <Grid.Column width={2}>
                    <Icon name="lab" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>In vitro Protocols</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row as={Link} to="/factors">
                  <Grid.Column width={2}>
                    <Icon name="setting" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>Experimental Factors</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row as={Link} to="/references">
                  <Grid.Column width={2}>
                    <Icon name="newspaper outline" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>References</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Header dividing textAlign="center">
                Contribute to the PEERS database
              </Header>
              <Grid padded>
                <Grid.Row
                  as={Link}
                  to={{ pathname: '/protocols/new', state: { returnTo: '/' } }}
                >
                  <Grid.Column width={2}>
                    <Icon name="paw" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>Add new Protocol</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row as={Link} to="/protocols">
                  <Grid.Column width={2}>
                    <Icon name="setting" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>Add new Factor</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  as={Link}
                  to={{ pathname: '/references/new', state: { returnTo: '/' } }}
                >
                  <Grid.Column width={2}>
                    <Icon name="newspaper outline" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>Add new Reference</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row as={Link} to="/protocols">
                  <Grid.Column width={2}>
                    <Icon name="clipboard list" size="large" />
                  </Grid.Column>
                  <Grid.Column width={12}>Add new Scorecard</Grid.Column>
                  <Grid.Column width={2}>
                    <Icon name="long arrow alternate right" size="large" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
}

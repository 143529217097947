import { useEffect, useState } from 'react';
import {
  Route,
  Link,
  NavLink,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';

import {
  Button,
  Form,
  Header,
  Icon,
  Label,
  Table,
  Grid,
  Menu,
  Divider,
  Segment,
  Modal,
} from 'semantic-ui-react';

import { PROTOCOL_TYPE_CHOICES } from './Protocol';

import { useProfile } from './queries/profile';
import {
  useAddFeature,
  useAddFeatureCategory,
  useDeleteFeature,
  useDeleteFeatureCategory,
  useFeature,
  useFeatureCategories,
  useFeatureCategory,
  useFeatures,
  useUpdateFeature,
  useUpdateFeatureCategory,
} from './queries/features';

import _ from 'lodash';

function FeaturesTable({
  baseUrl,
  editable,
  activeFeatureId,
  setActiveFeatureId,
}) {
  const { categoryId } = useParams();
  const features = useFeatures(categoryId);

  const { data: profile } = useProfile();

  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell colSpan={2} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(features.data, (feature) => (
          <Table.Row key={feature.id}>
            {feature.id === activeFeatureId ? (
              <Table.Cell colSpan={6}>
                <FeatureEdit
                  baseUrl={baseUrl}
                  featureId={activeFeatureId}
                  onClose={() => setActiveFeatureId()}
                />
              </Table.Cell>
            ) : (
              <>
                <Table.Cell>{feature.name}</Table.Cell>
                <Table.Cell collapsing>
                  {feature.is_owned && (
                    <Icon
                      color="green"
                      name="user"
                      title="Contributed by current user"
                    />
                  )}
                  {!feature.is_public && (
                    <Icon
                      color="grey"
                      name="eye slash"
                      title="Private Factor"
                    />
                  )}
                </Table.Cell>
                <Table.Cell collapsing>
                  {
                    FEATURE_TYPE_CHOICES.find(
                      (c) => c.value === feature.protocol_type
                    )?.text
                  }
                </Table.Cell>
                <Table.Cell collapsing>
                  {feature.is_generic && (
                    <Label color="black" content="G" title="Generic" />
                  )}
                </Table.Cell>
                <Table.Cell collapsing>
                  {editable && (profile?.is_coordinator || feature.is_owned) && (
                    <Button
                      onClick={() => setActiveFeatureId(feature.id)}
                      icon="edit"
                      primary
                      compact
                    />
                  )}
                </Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  <Label
                    color="teal"
                    content="Protocols"
                    detail={feature.parameter_count}
                    as={feature.parameter_count === 0 ? undefined : Link}
                    to={`/explore?factor=${feature.id}`}
                  />
                </Table.Cell>
              </>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function FeatureEdit({ baseUrl, featureId, onClose }) {
  const history = useHistory();
  const { categoryId } = useParams();

  const feature = useFeature(featureId);

  const [featureData, setFeatureData] = useState(() => ({}));
  useEffect(() => {
    if (feature.data) {
      // HACK
      setFeatureData({ ...feature.data, category: feature.data.category.id });
    }
  }, [feature.data]);

  const updateFeature = useUpdateFeature(featureId);

  const handleSubmit = () => {
    updateFeature.mutate(featureData, {
      onSuccess: ({ category }) => {
        onClose?.();

        // HACK
        if (category.id !== parseInt(categoryId)) {
          history.push(`${baseUrl}/categories/${category.id}`);
        }
      },
    });
  };

  const handleChange = (e, { name, value }) => {
    setFeatureData((d) => ({ ...d, [name]: value }));
  };

  const deleteFeature = useDeleteFeature();
  const handleDelete = () => {
    deleteFeature.mutate(featureId, {
      onSuccess: onClose,
    });
  };

  const featureCategories = useFeatureCategories();
  const categories = _.map(featureCategories.data, (c) => ({
    key: c.id,
    text: c.name,
    value: c.id,
  }));

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Input
        label="Name"
        name="name"
        value={featureData.name ?? ''}
        onChange={handleChange}
      />

      <Form.Group widths="equal">
        <Form.Dropdown
          selection
          label="Protocol type"
          name="protocol_type"
          value={featureData.protocol_type ?? null}
          options={FEATURE_TYPE_CHOICES}
          onChange={handleChange}
        />

        <Form.Dropdown
          selection
          label="Category"
          name="category"
          value={featureData.category ?? null}
          options={categories}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group inline>
        <Form.Button icon="save" content="Save" primary />
        {feature.data?.parameter_count === 0 && (
          <Form.Button
            type="button"
            icon="delete"
            content="Delete"
            negative
            onClick={handleDelete}
          />
        )}
        <Form.Button type="button" content="Cancel" onClick={onClose} />
      </Form.Group>
    </Form>
  );
}

const FEATURE_TYPE_CHOICES = PROTOCOL_TYPE_CHOICES.concat({
  key: 3,
  text: 'Any',
  value: 'ANY',
});

function FeatureNew({ onClose }) {
  const { categoryId } = useParams();

  const [featureData, setFeatureData] = useState(() => ({
    protocol_type: 'ANY',
  }));

  const addFeature = useAddFeature(categoryId);
  const handleSubmit = () => {
    addFeature.mutate(featureData, {
      onSuccess: onClose,
    });
  };

  const handleChange = (e, { name, value }) => {
    setFeatureData((d) => ({ ...d, [name]: value }));
  };

  return (
    <Modal
      size="tiny"
      open
      dimmer="inverted"
      centered={false}
      onClose={onClose}
    >
      <Modal.Header>New Generic Factor</Modal.Header>
      <Modal.Content>
        <Form id="createFeatureForm" onSubmit={handleSubmit}>
          <Form.Input
            required
            label="Name"
            name="name"
            value={featureData.name}
            onChange={handleChange}
          />

          <Form.Dropdown
            required
            selection
            label="Protocol type"
            name="protocol_type"
            value={featureData.protocol_type}
            options={FEATURE_TYPE_CHOICES}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button form="createFeatureForm" type="submit" content="Save" primary />
        <Button content="Cancel" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
}

function FeatureCategoryEdit({ onClose }) {
  const { categoryId } = useParams();
  const category = useFeatureCategory(categoryId);

  const [categoryData, setCategoryData] = useState(() => ({}));
  useEffect(() => {
    if (category.data) {
      setCategoryData(category.data);
    }
  }, [category.data]);

  const updateFeatureCategory = useUpdateFeatureCategory(categoryId);

  const handleSubmit = () => {
    updateFeatureCategory.mutate(categoryData, {
      onSuccess: onClose,
    });
  };

  const handleChange = (e, { name, value }) => {
    setCategoryData((d) => ({ ...d, [name]: value }));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Input
        label="Name"
        name="name"
        value={categoryData.name}
        onChange={handleChange}
      />
      <Form.Group inline>
        <Form.Button icon="save" content="Save" primary />
        <Form.Button content="Cancel" onClick={onClose} />
      </Form.Group>
    </Form>
  );
}

function FeatureCategoryNew({ onClose }) {
  const [categoryData, setCategoryData] = useState(() => ({}));

  const addFeatureCategory = useAddFeatureCategory();
  const handleSubmit = () => {
    addFeatureCategory.mutate(categoryData, {
      onSuccess: onClose,
    });
  };

  const handleChange = (e, { name, value }) => {
    setCategoryData((d) => ({ ...d, [name]: value }));
  };

  return (
    <Modal
      size="tiny"
      open
      dimmer="inverted"
      centered={false}
      onClose={onClose}
    >
      <Modal.Header>New Factor Category</Modal.Header>
      <Modal.Content>
        <Form id="createCategoryForm" onSubmit={handleSubmit}>
          <Form.Input
            required
            label="Name"
            name="name"
            value={categoryData.name}
            onChange={handleChange}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          form="createCategoryForm"
          type="submit"
          content="Save"
          primary
        />
        <Button content="Cancel" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
}

function FeatureCategory({ baseUrl, onDelete }) {
  const history = useHistory();
  const match = useRouteMatch();

  const { categoryId } = useParams();
  const { data: category } = useFeatureCategory(categoryId);

  const [editMode, setEditMode] = useState(false);
  const [activeFeatureId, setActiveFeatureId] = useState();

  const deleteFeatureCategory = useDeleteFeatureCategory();

  const handleDelete = () => {
    deleteFeatureCategory.mutate(categoryId, {
      onSuccess: onDelete,
    });
  };

  const { data: profile } = useProfile();
  const showEditButtons = profile && !editMode && !activeFeatureId;

  const canModify = profile?.is_coordinator || category?.is_owned;
  const canDelete = canModify && category?.parameter_count === 0;

  return (
    <>
      <Header as="h2">
        <div>
          Category: <em>{category?.name}</em>
          {category && !category.is_public && <Label content="Private" />}
          {showEditButtons && canModify && (
            <Button
              icon="edit"
              primary
              compact
              floated="right"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </Header>

      {editMode && (
        <Segment secondary>
          <FeatureCategoryEdit onClose={() => setEditMode(false)} />
        </Segment>
      )}

      <FeaturesTable
        baseUrl={baseUrl}
        editable={showEditButtons}
        activeFeatureId={activeFeatureId}
        setActiveFeatureId={setActiveFeatureId}
      />

      {canDelete && (
        <Button
          negative
          content="Delete category"
          icon="delete"
          onClick={handleDelete}
        />
      )}

      {profile?.is_coordinator && (
        <Button
          positive
          content="Add generic Factor"
          icon="plus"
          floated="right"
          as={Link}
          to={`${match.url}/factors/new`}
        />
      )}

      <Divider hidden clearing />

      <Route path={`${match.path}/factors/new`}>
        <FeatureNew onClose={() => history.push(match.url)} />
      </Route>
    </>
  );
}

export function FeatureCatalog() {
  const history = useHistory();
  const match = useRouteMatch();

  const featureCategories = useFeatureCategories();
  const { data: profile } = useProfile();

  return (
    <>
      <Grid>
        <Grid.Column width={4}>
          <Menu vertical secondary fluid>
            <Menu.Item header>Categories</Menu.Item>
            {_.map(featureCategories.data, (category) => (
              <Menu.Item
                key={category.id}
                as={NavLink}
                to={`${match.url}/categories/${category.id}`}
              >
                {!category.is_public && (
                  <Icon
                    name="eye slash"
                    color="grey"
                    title="Private category"
                  />
                )}
                {category.name}
              </Menu.Item>
            ))}
          </Menu>

          {profile?.is_coordinator && (
            <Button
              positive
              fluid
              content="Add public category"
              icon="plus"
              as={Link}
              to={`${match.url}/categories/new`}
            />
          )}
          <Divider hidden clearing />
        </Grid.Column>
        <Grid.Column width={12}>
          <Route path={`${match.path}/categories/:categoryId(\\d+)`}>
            <FeatureCategory
              baseUrl={match.url}
              onDelete={() => history.push(match.url)}
            />
          </Route>
        </Grid.Column>
      </Grid>

      <Route path={`${match.path}/categories/new`}>
        <FeatureCategoryNew onClose={() => history.push(match.url)} />
      </Route>
    </>
  );
}

import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button, Container, Divider, Form, Message, Modal } from 'semantic-ui-react'

import { useToken } from './context/token';

import axios from 'axios';
import qs from 'qs';


export default function Login() {
  const location = useLocation();
  const { returnTo='/' } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const history = useHistory();
  const { setToken } = useToken();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post('/api/auth/token/login/', {
        email,
        password,
      })
      .then(
        ({ data }) => {
          setToken(data.auth_token);
          history.replace(returnTo);
        },
        (err) => {
          // do nothing?
          setError(true);
        }
      );
  };

  useEffect(() => setError(false), [email, password]);

  return (
    <Modal open dimmer="inverted" centered={false} size="tiny" onClose={() => history.replace(returnTo)}>
      <Modal.Header>
        Sign into PEERS
      </Modal.Header>
      <Modal.Content>
        <Form id="loginForm" size="large" onSubmit={handleSubmit} error={error}>
          <Form.Input
            icon="mail"
            iconPosition="left"
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Input
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Message error content="Could not log you in, please try again." />

          <Container textAlign="center">
            <Divider />
            <Link to="/password/reset">Forgot my password</Link>
          </Container>

        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button form="loginForm" primary size="large" content="Login" />
        <Button size="large" as={Link} to={returnTo} content="Cancel" />
      </Modal.Actions>
    </Modal>
  );
}

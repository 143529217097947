import { useState } from 'react';
import { Link, Route, Redirect, useRouteMatch, useHistory, useParams } from 'react-router-dom';

import { Form, Search } from 'semantic-ui-react';

import { ParameterAdd } from '../Parameter';

import { useProtocolParameters } from '../queries/parameters';
import { useSearchFeatures } from '../queries/features';
import { useDebounce } from '../hooks/useDebounce';

import qs from 'qs';
import _ from 'lodash';


function RedirectFeatureToParameter() {
  const { protocolId, featureId } = useParams();
  const match = useRouteMatch();

  const parameters = useProtocolParameters({ protocol: protocolId, feature: featureId });
  if (parameters.isLoading) return null;

  const parameter = parameters.data?.[0];

  if (parameter) {
    return <Redirect to={`/protocols/${protocolId}/parameters/${parameters.data?.[0].id}`} />;
  } else {
    return <Redirect to={{ pathname: `${match.url}/../new`, search: '?' + qs.stringify({ featureId })}} />;
  }

}

export default function FeatureSearchBox({ create, size='huge', ...props }) {
  const { protocolId } = useParams();

  const match = useRouteMatch();
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const searchFeatures = useSearchFeatures({ search: debouncedSearchTerm });

  const handleSelectFeature = (e, { result }) => {
    setSearchTerm(result?.title || '');
    const goTo = create
      ? `${match.url}/${result.id}`
      : {
          pathname: '/explore',
          search: '?' + qs.stringify({ factor: result.id }),
        };

    history.push(goTo);
  };

  const handleSubmit = (e) => {
    const goTo = create
      ? {
          pathname: `${match.url}/new`,
          search: '?' + qs.stringify({ featureName: searchTerm }),
        }
      : {
          pathname: '/explore',
          search: '?' + qs.stringify({ search: searchTerm }),
        };

    history.push(goTo);
  };

  const searchResults = _.filter(
    searchFeatures.data,
    ({ parameter_count }) => parameter_count > 0
  ).map((f) => ({
    id: f.id,
    title: f.name,
    description: f.category.name,
  }));

  const emptyResult = debouncedSearchTerm && searchResults.length === 0;

  const inputAction = (create && emptyResult )? {
    size,
    positive: true,
    icon: 'plus',
    title: 'Add custom Factor',
    as: Link,
    to: { pathname: `${match.url}/new`, search: '?' + qs.stringify({ featureName: searchTerm })}
  } : undefined;

  return (<>
    <Form size={size} {...props} onSubmit={handleSubmit}>
      <Form.Field>
        <Search
          input={{ iconPosition: 'left', action: inputAction }}
          fluid
          loading={searchFeatures.isLoading}
          showNoResults={false}
          minCharacters={0}
          placeholder={`Type to search for a Factor${create ? ' or add a new one' : ''}`}
          value={searchTerm}
          onSearchChange={(e, { value }) => setSearchTerm(value)}
          results={searchResults}
          onResultSelect={handleSelectFeature}
        />
      </Form.Field>
    </Form>
    <Route path={`${match.path}/new`}>
      <ParameterAdd modal size="large" onClose={() => history.replace(match.url)} onAdded={({ id }) => history.replace(`/protocols/${protocolId}/parameters/${id}`)} />
    </Route>
    <Route path={`${match.path}/:featureId(\\d+)`}>
      <RedirectFeatureToParameter />
    </Route>
  </>);
}

import { useMutation, useQuery, useQueryClient } from 'react-query';

import axios from 'axios';


export const useScorecards = ({ evidence, contributor }={}, options) => {
  const params = { evidence, contributor };
  return useQuery(
    ['scorecards', 'list', evidence, contributor],
    () => axios.get('/api/scorecards/', { params }).then(({ data }) => data),
    options
  );
};

export const useScorecard = (id) =>
  useQuery(['scorecards', 'details', id], () =>
    axios.get(`/api/scorecards/${id}/`).then(({ data }) => data)
  );


export const useAddScorecard = (evidenceId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) =>
      axios
        .post('/api/scorecards/', { evidence: evidenceId, ...data })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        // we can be lazy about this, not needed directly
        queryClient.invalidateQueries(['parameters']);

        // let's wait for these
        return Promise.all([
          queryClient.invalidateQueries(['scorecards', 'list', evidenceId]),
          // TODO maybe provide the parameter so that we can invalidate just the ones we want
          queryClient.invalidateQueries(['evidence']),
        ]);
      },
    }
  );
};

export const useUpdateScorecard = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    (scorecard) =>
      axios.patch(`/api/scorecards/${id}/`, scorecard).then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['scorecards', 'details', id], data);

        // we can be lazy about this, not needed directly
        queryClient.invalidateQueries(['parameters']);

        // let's wait for these
        return Promise.all([
          // TODO maybe provide the evidenceId so that we can invalidate just the ones we want
          queryClient.invalidateQueries(['scorecards', 'list']),
          // TODO maybe provide the parameterId so that we can invalidate just the ones we want
          queryClient.invalidateQueries(['evidence']),
        ]);
      },
    }
  );
};

export const useDeleteScorecard = (evidenceId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/scorecards/${id}/`),
    { 
      onSuccess: () => {
        // we can be lazy about this, not needed directly
        queryClient.invalidateQueries(['parameters']);

        // let's wait for these
        return Promise.all([
          queryClient.invalidateQueries(['scorecards', 'list', evidenceId], { refetchInactive: true }),
          // TODO maybe provide the parameter so that we can invalidate just the ones we want
          queryClient.invalidateQueries(['evidence']),
        ]);
      },
    }
  );
};

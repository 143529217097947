import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useToken } from '../context/token';

import axios from 'axios';


export const useProfile = () => {
  const { token, setToken } = useToken();

  return useQuery(
    ['profile', { token }],
    () => !!token && axios.get('/api/profile/').then(
      ({ data }) => data,
      (err) => {
        if (err.response?.status === 401) {
          console.warn(err.response.data?.detail);
          if (err.response.data?.detail === 'User inactive or deleted.') {
            // token invalid so bugger off
            setToken(null);
          } else if (err.response.data?.detail === 'Invalid token.') {
            // token invalid so bugger off
            setToken(null);
          }
        }
      }
    ), {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ first_name, last_name, is_public, is_coordinator, competence }) =>
      axios
        .patch('/api/profile/', {
          first_name,
          last_name,
          is_public,
          is_coordinator,
          competence,
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profile']);
      },
    }
  );
};

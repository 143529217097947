import { useQuery } from 'react-query';

import axios from 'axios';


export const useContributors = ({ forEvidence }={}) => {
  const params = { for_evidence: forEvidence };
  return useQuery(['contributors', { forEvidence }], () =>
    axios.get('/api/contributors/', { params }).then(({ data }) => data)
  );
};

export const useContributor = (id) =>
  useQuery(['contributors', id], () =>
    axios.get(`/api/contributors/${id}/`).then(({ data }) => data)
  );


import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useAddParameter } from './parameters';

import axios from 'axios';


const useListEvidence = ({ protocol, reference, parameter, feature }) => {
  const params = { protocol, reference, parameter, feature };
  return  useQuery(['evidence', params], () =>
    axios.get('/api/evidence/', { params }).then(({data}) => data)
  );
};

export const useLiteratureEvidence = ({ protocol, reference }) => 
  useListEvidence({ protocol, reference });

export const useParameterEvidence = (parameterId) =>
  useListEvidence({ parameter: parameterId });


export const useEvidence = (id) =>
  useQuery(['evidence', id], () =>
    axios.get(`/api/evidence/${id}/`).then(({ data }) => data)
  );


export const useAddEvidence = (parameterId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (referenceId) =>
      axios
        .post('/api/evidence/', { parameter: parameterId, reference: referenceId })
        .then(({ data }) => data),
    {
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(['evidence', { parameter: parameterId }]),
        queryClient.invalidateQueries(['parameters']),
      ]),
    }
  );
};

export const useAddLiteratureEvidence = ({ protocol, reference }) => {
  const queryClient = useQueryClient();
  const addParameter = useAddParameter(protocol);

  return useMutation(
    ({ feature, customFeature, category, customCategory, range }) =>
      addParameter
        .mutateAsync({ feature, customFeature, category, customCategory, range })
        .then((parameter) =>
          // we now are sure we have a parameter id
          // BUT we may already have evidence for that parameter for this reference
          axios
            .get('/api/evidence/', { params: { parameter: parameter.id, reference } })
            .then(({ data: evidence }) => {
              // if evidence is found, return it (we expect it to be unique)
              if (evidence.length) return evidence[0];
    
              // else create and return it
              return axios
                .post('/api/evidence/', { parameter: parameter.id, reference })
                .then(({ data }) => data)
            })
          ),
    {
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(['evidence', { protocol, reference }]),
        queryClient.invalidateQueries(['protocols']),
      ]),
    }
  );
}

export const useDeleteEvidence = (parameterId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/evidence/${id}/`),
    { 
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(['evidence', { parameter: parameterId }]),
        queryClient.invalidateQueries(['parameters']),
      ]),
    }
  );
};

export const useDeleteLiteratureEvidence = ({ protocol, reference }) => {
  const queryClient = useQueryClient();
  return useMutation(
    (id) => axios.delete(`/api/evidence/${id}/`),
    { 
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries(['evidence', { protocol, reference }]),
        queryClient.invalidateQueries(['protocols']),
      ]),
    }
  );
};

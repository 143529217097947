import { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import {
  Header,
  Modal,
  Form,
  Message,
  Button,
  Divider,
} from 'semantic-ui-react';

import { useMutation } from 'react-query';

import { useToken } from '../context/token';

import axios from 'axios';

function PasswordErrorMessage({ keywords, ...props }) {
  const messageLines = [],
    messageItems = [];

  if (keywords?.includes('password mismatch')) {
    messageLines.push(
      'You must repeat typing the same password, there was a mismatch between the values in those fields.'
    );
  }

  let passwordError = false;
  if (keywords?.includes('short password')) {
    messageItems.push(
      'This password is too short. It must contain at least 8 characters.'
    );
    passwordError = true;
  }
  if (keywords?.includes('common password')) {
    messageItems.push('This password is too common..');
    passwordError = true;
  }
  if (keywords?.includes('numeric password')) {
    messageItems.push('This password is entirely numeric.');
    passwordError = true;
  }
  if (keywords?.includes('similar email password')) {
    messageItems.push('The password is too similar to the email address.');
    passwordError = true;
  }

  if (keywords?.includes('invalid token')) {
    messageLines.push('The password reset link has expired or is not valid.');
    messageLines.push(
      <>
        You can request a new password reset link{' '}
        <Link to="/password/reset">here</Link>.
      </>
    );
  }

  if (keywords?.includes('invalid current password')) {
    messageLines.push('Please enter the current password correctly.');
  }

  let header = 'Could not set password';
  if (passwordError) {
    header = 'Invalid password';
  }

  return (
    <Message {...props} error>
      <Message.Header>{header}</Message.Header>
      <Divider />
      {messageLines.length > 0 && (
        <p>
          {messageLines.map((content, index) => (
            <div key={index}>{content}</div>
          ))}
        </p>
      )}
      {messageItems.length > 0 && <Message.List items={messageItems} />}
    </Message>
  );
}

export function PasswordResetModal() {
  const history = useHistory();

  const [email, setEmail] = useState('');

  const resetMutation = useMutation(() =>
    axios.post('/api/auth/users/reset_password/', { email })
  );

  const handleChange = (e, { value }) => setEmail(value);

  const handleSubmit = (e) => resetMutation.mutate();
  const handleClose = () => history.push('/');

  return (
    <Modal
      centered={false}
      size="small"
      dimmer="inverted"
      open
      onClose={handleClose}
    >
      <Modal.Header>Password reset</Modal.Header>
      <Modal.Content>
        <Form
          id="request-password-reset"
          success={resetMutation.isSuccess}
          error={resetMutation.isError}
          onSubmit={handleSubmit}
        >
          <Header size="small">
            Please provide your email address and a password reset link for your
            account will be sent
          </Header>

          <Form.Input
            size="large"
            value={email}
            type="email"
            required
            readOnly={resetMutation.isSuccess}
            onChange={handleChange}
          />

          <Message success>
            <Message.Header>Password reset request accepted</Message.Header>
            <Message.Content>
              A message with further instructions has been sent to the email
              address you specified, provided an account has registered using
              that address.
            </Message.Content>
          </Message>

          <Message
            error
            header="Could not complete the password reset request"
            content="Please try again later."
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {!resetMutation.isSuccess && (
          <Button
            form="request-password-reset"
            primary
            content="Request password reset"
            loading={resetMutation.isLoading}
            disabled={resetMutation.isLoading}
          />
        )}
        <Button content="Return" onClick={handleClose} />
      </Modal.Actions>
    </Modal>
  );
}

export function PasswordChangeForm() {
  const history = useHistory();
  const { uid, token } = useParams();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const passwordMutation = useMutation(
    async ({ currentPassword, newPassword, repeatPassword, uid, token }) => {
      if (newPassword !== repeatPassword) {
        throw new Error('password mismatch');
      }

      const url = currentPassword
        ? '/api/auth/users/set_password/'
        : '/api/auth/users/reset_password_confirm/';
      try {
        const { data } = await axios.post(url, {
          uid,
          token,
          new_password: newPassword,
          current_password: currentPassword || undefined,
        });
        return data;
      } catch (error) {
        const tokenErrors = error.response.data['token'];
        if (tokenErrors?.includes('Invalid token for given user.')) {
          throw new Error('invalid token');
        }

        const authErrors = error.response.data['current_password'];
        if (authErrors?.includes('Invalid password.')) {
          throw new Error('invalid current password');
        }

        const passwordErrors = error.response.data['new_password'];
        if (passwordErrors) {
          let keywords = '';
          if (
            passwordErrors.includes(
              'This password is too short. It must contain at least 8 characters.'
            )
          ) {
            keywords += 'short password ';
          }
          if (passwordErrors.includes('This password is too common.')) {
            keywords += 'common password ';
          }
          if (passwordErrors.includes('This password is entirely numeric.')) {
            keywords += 'numeric password ';
          }
          if (
            passwordErrors.includes(
              'The password is too similar to the email address.'
            )
          ) {
            keywords += 'similar email password';
          }
          throw new Error(keywords);
        }

        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );

  const handleCurrentPasswordChange = (e, { value }) => {
    setCurrentPassword(value);
    passwordMutation.reset();
  };
  const handleNewPasswordChange = (e, { value }) => {
    setNewPassword(value);
    passwordMutation.reset();
  };
  const handleRepeatPasswordChange = (e, { value }) => {
    setRepeatPassword(value);
    passwordMutation.reset();
  };

  const handleSubmit = () =>
    passwordMutation.mutate({
      currentPassword,
      newPassword,
      repeatPassword,
      uid,
      token,
    });

  const { setToken } = useToken();
  const handleClose = () => {
    let redirectUrl = token ? '/' : '/profile';

    if (passwordMutation.isSuccess) {
      redirectUrl = '/login';
      if (!token) {
        // means we change the password, let's send the user to the login and also logout them out
        setToken();
      }
    }
    history.push(redirectUrl);
  };

  return (
    <Modal
      centered={false}
      size="small"
      dimmer="inverted"
      open
      onClose={handleClose}
    >
      <Modal.Header>Change password</Modal.Header>
      <Modal.Content>
        <Form
          id="password-reset-confirm"
          size="large"
          onSubmit={handleSubmit}
          success={passwordMutation.isSuccess}
          error={passwordMutation.isError}
        >
          {!passwordMutation.isSuccess && (
            <Form.Group grouped>
              {!token && (
                <Form.Input
                  value={currentPassword}
                  label="Old password"
                  type="password"
                  required
                  onChange={handleCurrentPasswordChange}
                />
              )}
              <Form.Input
                value={newPassword}
                label="New password"
                type="password"
                required
                onChange={handleNewPasswordChange}
              />
              <Form.Input
                value={repeatPassword}
                label="Repeat new password"
                type="password"
                required
                onChange={handleRepeatPasswordChange}
              />
            </Form.Group>
          )}

          <PasswordErrorMessage
            size="small"
            keywords={passwordMutation.error?.message}
          />

          <Message
            size="small"
            success
            header="The password for your account has been succesfully updated"
            content="You can now use the new password to access the platform."
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {!passwordMutation.isSuccess && (
          <Button
            form="password-reset-confirm"
            primary
            content="Change password"
            loading={passwordMutation.isLoading}
            disabled={passwordMutation.isLoading}
          />
        )}
        <Button
          content={passwordMutation.isSuccess ? 'Sign in' : 'Return'}
          onClick={handleClose}
        />
      </Modal.Actions>
    </Modal>
  );
}

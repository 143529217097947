import { Link, Route, Switch, NavLink, useHistory, useLocation } from 'react-router-dom';

import { Button, Container, Divider, Dropdown, Grid, Header, Icon, Image, List, Menu, Segment } from 'semantic-ui-react';

import Login from './Login';
import Register from './components/Register';
import { PeersLandingPage } from './PeersLandingPage';
import { ProtocolList, ProtocolParameterList } from './Protocol';
import { ReferenceList } from './Reference';
import { FeatureCatalog } from './Features';
import { ContributorProfile, Contributors } from './Contributor';
import { CodeOfConduct, Contact, Funding, Team } from './AdditionalPages';
import { PeersMission } from './PeersMission';
import { FrequentQuestions } from './FrequentQuestions';
import FeatureSearchBox from './components/FeatureSearchBox';

import { PasswordChangeForm, PasswordResetModal } from './components/PasswordReset';
import ActivateAccount from './components/ActivateAccount';

import { useToken } from './context/token';
import { useProfile } from './queries/profile';

import qs from 'qs';


export function PeersModels() {
  const { setToken } = useToken();
  const location = useLocation();
  const history = useHistory();

  const makeReturnRoute = (pathname, returnTo=location.pathname) => ({
    pathname,
    search: '?' + qs.stringify({ returnTo }),
  });

  const handleLogout = () => {
    if (location.pathname.startsWith('/profile')) {
      history.replace('/');
    }
    setToken();
  }

  const { data: profile } = useProfile();
  return (<div>
    <Segment basic vertical className="peers-main">
      <Container>
        <Menu text size="large" floated="right" className="peers-blue">
          <Menu.Item icon="mail" content="Contact Us" as={NavLink} to="/contact" />
          { profile ?
              <>
                <Menu.Item icon="user" content={profile.username} title="Visit profile page" as={NavLink} to="/profile" />
                <Menu.Item icon="sign-out" content="Sign out" link onClick={handleLogout} />
              </>
              :
              <>
                <Menu.Item icon="sign-in" content="Sign in" as={Link} to={makeReturnRoute('/login')} />
                <Menu.Item content={<Button icon="signup" content="Sign up free" positive />} as={Link} to={makeReturnRoute('/register')} />
              </>
          }
        </Menu>
        <Link to="/">
          <Image size="medium" src={process.env.PUBLIC_URL + '/peers_logo_green.png'} />
        </Link>

        <Header as="h1" className="peers-blue">
          The <u>P</u>latform for the <u>E</u>xchange of <u>E</u>xperimental{' '}
          <u>R</u>esearch <u>S</u>tandards
        </Header>

        <Menu inverted color="green" borderless size="large" stackable>
          <Menu.Item content="Home" as={NavLink} to="/" exact />
          <Menu.Item as={NavLink} to="/protocols">Protocols</Menu.Item>
          <Menu.Item as={NavLink} to="/references">References</Menu.Item>
          <Menu.Item as={NavLink} to="/factors">Factors</Menu.Item>
          <Menu.Item as={NavLink} to="/contributors">Contributors</Menu.Item>

          <Menu.Menu position="right">
            <Dropdown item trigger={<><Icon name="info circle" />About Peers</>}>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/about">Mission / Concept</Dropdown.Item>
                <Dropdown.Item as={Link} to="/team">Team</Dropdown.Item>
                <Dropdown.Item as={Link} to="/faq">FAQ</Dropdown.Item>
                <Dropdown.Item as={Link} to="/conduct">Code of conduct</Dropdown.Item>
                <Dropdown.Item as={Link} to="/funding">Funding</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>

        <Switch>
          <Route path="/" exact>
            <PeersLandingPage />
          </Route>
          <Route path="/explore">
            <ProtocolParameterList />
          </Route>
          <Route path="/protocols">
            <ProtocolList />
          </Route>
          <Route path="/references">
            <ReferenceList />
          </Route>
          <Route path="/factors">
            <Header as="h2" className="peers-blue">Factors catalogue</Header>
            <FeatureSearchBox size="large" />
            <Divider />
            <FeatureCatalog />
          </Route>
          <Route path="/contributors">
            <Contributors />
          </Route>

          <Route path="/about">
            <PeersMission />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/faq">
            <FrequentQuestions />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/conduct">
            <CodeOfConduct />
          </Route>
          <Route path="/funding">
            <Funding />
          </Route>

          <Route exact path="/password/reset" component={PasswordResetModal} />
          <Route path="/password/reset/confirm/:uid/:token" component={PasswordChangeForm} />
          <Route path="/account/activate/:uid/:token" component={ActivateAccount} />

          <Route path="/profile">
            <ContributorProfile profile={profile} />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>

        </Switch>
      </Container>

      <Divider section hidden />
    </Segment>

    <Segment basic color="green" size="small">
      <Container>
        <Image
          src={`${process.env.PUBLIC_URL}/GPDF-logo.png`}
          href="https://www.preclinicaldataforum.org"
          size="small"
          floated="left"
        />
        <p>
          The Global Preclinical Data Forum is a jointly sponsored U.S. and
          European initiative to encourage global collaboration to address the
          challenge of ensuring that preclinical research is reproducible,
          robust and translatable to support disease research utility for
          clinical research &amp; development (R&amp;D).
        </p>
      </Container>
    </Segment>

    <Segment inverted vertical className="peers-blue-inverted peers-footer">
      <Container>
        <Grid divided inverted stackable padded>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="About" />
              <List link inverted>
                <List.Item as={NavLink} to="/about">Mission / Concept</List.Item>
                <List.Item as={NavLink} to="/team">Team</List.Item>
                <List.Item as={Link} to="/faq">FAQ</List.Item>
                <List.Item as={NavLink} to="/conduct">Code of conduct</List.Item>
                <List.Item as={NavLink} to="/funding">Funding</List.Item>
                <List.Item as={NavLink} to="/contact">Contact</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header inverted as="h4" content="Resources" />
              <List link inverted>
                <List.Item as={NavLink} to="/protocols">Protocols</List.Item>
                <List.Item as={NavLink} to="/references">References</List.Item>
                <List.Item as={NavLink} to="/factors">Factors</List.Item>
                <List.Item as={NavLink} to="/contributors">Contributors</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={6}>
              <p>The development of PEERS was funded by Cohen Veterans Bioscience and grants COH-0011 from Steven A. Cohen.</p>
              <Image size="small" rounded
                src={process.env.PUBLIC_URL + '/cvb-logo-jpg.jpg'}
                href="https://www.cohenveteransbioscience.org"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>

  </div>);
}

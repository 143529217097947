import { useState, useEffect } from 'react';
import { Switch, Route, Link, useHistory, useRouteMatch, useParams, useLocation, matchPath } from 'react-router-dom';

import { Button, Card, Divider, Form, Grid, Header, Icon, Image, Input, Label, Loader, Message, Modal, Popup, Segment, Table } from 'semantic-ui-react';

import { PROTOCOL_TYPE_CHOICES } from './Protocol';
import { ScoreElement } from './Parameter';
import { LiteratureEvidenceAdd, LiteratureProtocolEvidenceAdd } from './Evidence';
import { Scorecard, ScorecardAdd, ScorecardTable } from './Scorecard';
import ProtocolSearchBox from './components/ProtocolSearchBox';

import { useProfile } from './queries/profile';
import { useAddReference, useDeleteReference, useReference, useReferenceMetadata, useReferences, useUpdateReference } from './queries/references';
import { useDeleteLiteratureEvidence, useEvidence, useLiteratureEvidence } from './queries/evidence';
import { useProtocol, useProtocols } from './queries/protocols';

import { useDebounce } from './hooks/useDebounce';

import _ from 'lodash';
import qs from 'qs';


export const PAPER_TYPE_CHOICES = [
  { key: 1, value: 'ORG', text: 'Original research'},
  { key: 2, value: 'REV', text: 'Review'},
  { key: 3, value: 'SYS', text: 'Systematic review / meta-analysis'},
];

const pubmedLogo = `${process.env.PUBLIC_URL}/pubmedIcon.png`;
const doiLogo = `${process.env.PUBLIC_URL}/DOI_Logo_TM.png`;

const ReferenceCard = ({ reference, editUrl, viewUrl, ...props }) => {
  const { title, url, doi, pmid, paper_type, published_post_2010, contributor, is_owned } = reference || {};

  const { data: profile } = useProfile();
  const canModify = is_owned || profile?.is_staff;

  return (
    <Card fluid {...props}>
      <Card.Content>
        <Card.Header>{ title }</Card.Header>
        <Card.Meta>
          { _.find(PAPER_TYPE_CHOICES, { value: paper_type })?.text }
        </Card.Meta>
        <Card.Meta style={{ color: published_post_2010 === null ? 'red': undefined }}>
          Published {published_post_2010 === null ? '???' : ( published_post_2010 ? 'post-2010' : 'pre-2010' )}
        </Card.Meta>
        <Card.Description>
          <a href={url} target="_blank" rel="noreferrer">
            <Image inline src={doi ? doiLogo : pubmedLogo}/>{' '}{doi || pmid}
          </a>
        </Card.Description>
      </Card.Content>
      { canModify &&
        <Card.Content extra>
          <Icon name="user" color={is_owned ? 'green': undefined} />
          Contributed by {is_owned ? 'current user' : contributor?.username}
        </Card.Content>
      }
      { viewUrl ?
          <Card.Content>
            <Button floated="right" icon="info" content="Details" as={Link} to={viewUrl} />
            { canModify && editUrl &&
              <Button floated="left" icon="edit" content="Edit" primary as={Link} to={editUrl} />
            }
          </Card.Content>
        : canModify && editUrl &&
          <Card.Content>
            <Button floated="left" icon="edit" content="Edit" primary as={Link} to={editUrl} />
          </Card.Content>
      }
    </Card>
  );
};

export const ReferenceForm = ({ data, onChange, actions, errorList, onSubmit, ...props }) => {
  const handleFieldChange = (e, { name, value }) => {
    if (typeof value === 'string') value = value.trim();
    onChange((previous) => ({ ...previous, [name]: value }));
  };

  const { title, doi, pmid, url, paper_type, year_published } = data;
  const urlValue =
    url ||
    (doi && `https://doi.org/${doi}`) ||
    (pmid && `https://pubmed.ncbi.nlm.nih.gov/${pmid}`) ||
    '';

  const handleSubmit = (e) => {
    // before submitting, make sure we have some URL in the data
    if (!data.url) {
      // HACK
      data.url = urlValue;
    }
    onSubmit(e);
  };

  const doiMetadata = useReferenceMetadata(doi, 'doi');
  const pubmedMetadata = useReferenceMetadata(pmid, 'pubmed');

  const handleFetchMetadata = (doi) => () => {
    const referenceMetadata = doi ? doiMetadata : pubmedMetadata;
    referenceMetadata.refetch().then(
      ({ data: { title, doi, pmid, url, year_published }={}}) => onChange((previous) => ({ ...previous, title, url, doi, pmid, year_published }))
    );
  }

  return (
    <Form onSubmit={handleSubmit} {...props}>
      <Form.Group>
        <Form.Input width={6} name="doi" label="DOI" value={doi} onChange={handleFieldChange}
          action={{
            title: 'Autofill metadata from DOI', type: 'button', icon: 'download',
            onClick: handleFetchMetadata(true),
          }}
        />
        <Form.Input width={6} name="pmid" label="PubMed ID" value={pmid} onChange={handleFieldChange}
          action={{
            title: 'Autofill metadata from PubMed ID', type: 'button', icon: 'download',
            onClick: handleFetchMetadata(false),
          }}
        />

        <Form.Dropdown width={4} required selection
          name="paper_type"
          label="Paper type"
          value={paper_type}
          options={PAPER_TYPE_CHOICES}
          onChange={handleFieldChange}
        />
      </Form.Group>

      <Form.Group>
        <Form.Input width={12} required label="Title"
          name="title" value={title} onChange={handleFieldChange}
        />
        <Form.Input width={4} required label="Year published" input={{ type: 'number' }}
          name="year_published" value={year_published || ''} onChange={handleFieldChange}
        />
      </Form.Group>

      <Form.Input name="url" label="URL"
        value={urlValue}
        input={{ readOnly: true }}
      />

      { actions && <Divider /> }
      { actions }

      <Message error header="Could not save the Reference details, please try again." list={errorList} />

    </Form>
  );

};

const ReferenceEdit = ({ id, referenceId, actions=true, onClose }) => {
  const reference = useReference(referenceId);
  const [referenceData, setReferenceData] = useState(reference.data);

  useEffect(() => {
    if (reference.data) {
      setReferenceData(reference.data);
    }
  }, [reference.data]);

  const updateReference = useUpdateReference(referenceId);

  const errorList = _.flatMap(updateReference.error?.response.data, (errors) => errors);

  const handleSubmit = () =>
    updateReference.mutate(referenceData, {
      onSuccess: onClose,
    });

  return (
    <ReferenceForm id={id} data={referenceData} onChange={setReferenceData} onSubmit={handleSubmit}
      errorList={errorList} error={updateReference.isError}
      actions={actions &&
        <Form.Group inline>
          <Form.Button icon="save" content="Save" primary />
          <Form.Button type="button" content="Cancel" onClick={onClose} />
        </Form.Group>
      }
    />
  );
};

const ReferenceView = ({ referenceId, ...props }) => {
  const reference = useReference(referenceId);
  return <ReferenceCard reference={reference.data} {...props} />
}

export const ReferenceModal = ({ edit, ...props }) => {
  const { referenceId } = useParams();

  return (
    <Modal {...props} open>
      <Modal.Header>
        { edit ? 'Edit Reference' : 'Reference details' }
      </Modal.Header>
      <Modal.Content>
        { edit ?
            <ReferenceEdit id="editReferenceModalForm" referenceId={referenceId} actions={false} onClose={props.onClose} />
          :
            <ReferenceView referenceId={referenceId} />
        }
      </Modal.Content>
      <Modal.Actions>
        { edit && <Button form="editReferenceModalForm" icon="save" content="Save" primary /> }
        <Button content={edit ? 'Cancel' : 'Close'} onClick={props.onClose} />
      </Modal.Actions>
    </Modal>
  );
}

export const Reference = ({ referenceId }) => {
  const history = useHistory();
  const match = useRouteMatch();

  if (!referenceId) return null;

  return (
    <Switch>
      <Route path={`${match.path}/edit`}>
        <Segment secondary>
          <ReferenceEdit referenceId={referenceId} onClose={() => history.push(match.url)} />
        </Segment>
      </Route>
      <Route>
        <ReferenceView referenceId={referenceId} editUrl={`${match.url}/edit`} />
      </Route>
    </Switch>
  );
};

const ReferenceAdd = ({ onAdded, onClose }) => {
  const history = useHistory();
  const { state: { returnTo } = {} } = useLocation();

  const handleClose = () => {
    if (returnTo) {
      history.replace(returnTo);
    } else {
      onClose?.();
    }
  };

  const [referenceData, setReferenceData] = useState(() => ({
    title: '',
    url: '',
    doi: '',
    paper_type: 'ORG',
    year_published: null,
  }));
  const addReference = useAddReference();

  const errorList = _.flatMap(addReference.error?.response.data, (errors) => errors);

  const handleCreateReference = () =>
    addReference.mutate(referenceData, {
      onSuccess: onAdded
    });

  return (
    <Modal centered={false} dimmer="inverted" open onClose={onClose}>
      <Modal.Header>New paper</Modal.Header>
      <Modal.Content>
        <ReferenceForm id="createReferenceForm" data={referenceData} onChange={setReferenceData}
          onSubmit={handleCreateReference}
          errorList={errorList} error={addReference.isError}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} content="Cancel" />
        <Button primary icon="save" content="Add" form="createReferenceForm" />
      </Modal.Actions>
    </Modal>
  );
};

const ParameterEvidenceScorecards = ({ onClose, onRemoved }) => {
  const { referenceId, protocolId, evidenceId } = useParams();
  const evidence = useEvidence(evidenceId);

  const protocol = useProtocol(protocolId);

  const history = useHistory();
  const match = useRouteMatch();
  const { pathname } = useLocation();

  const viewScorecard = !!matchPath(pathname, `${match.path}/scorecards`);

  useEffect(() => {
    // handle no Scorecards for existing evidence
    if (evidence.data?.scorecards.length === 0 && viewScorecard === false) {
      // no reason to show an empty modal, close it
      onRemoved?.();
    }
  }, [evidence, viewScorecard, onRemoved]);

  const deleteEvidence = useDeleteLiteratureEvidence({ protocol: protocolId, reference: referenceId });
  const handleScorecardRemoved = () => {
    // first, return to current component
    history.replace(match.url);

    // also check if we have no Scorecards, so we delete this evidence too
    // if we are not allowed to, no harm done.
    evidence.refetch().then(({ data }) => {
      if (data?.scorecards_count === 0) {
        // this should get us to some other url eventually
        deleteEvidence.mutate(evidenceId, {
          onSuccess: onRemoved,
        });
      }
    });
  };

  return (<>
    <Modal centered={false} dimmer="inverted" open onClose={onClose}>
      <Modal.Header>Scorecards for <u>{ evidence.data?.parameter.feature.name }</u> in <u>{ protocol.data?.name }</u></Modal.Header>
      <Modal.Content>
        <Loader disabled={!deleteEvidence.isLoading} />
        <ScorecardTable />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={onClose} />
      </Modal.Actions>

    </Modal>

    <Route path={`${match.path}/scorecards/:scorecardId(\\d+)`}>
      <Scorecard onClose={() => history.push(match.url)} onRemoved={handleScorecardRemoved} />
    </Route>

    <Route path={`${match.path}/scorecards/new`}>
      <ScorecardAdd onClose={handleScorecardRemoved} onSave={() => history.replace(match.url)} />
    </Route>

  </>)
};

const ParameterEvidenceRow = ({ id, is_owned, score, scorecards_count, parameter: { protocol: protocolId, feature: { name: featureName, category: { name: categoryName } } } }) => {
  const match = useRouteMatch();
  const { data: profile } = useProfile();

  return (<>
    <Table.Row>
      <Table.Cell><Popup mouseEnterDelay={500} trigger={<span>{featureName}</span>} content={categoryName} /></Table.Cell>
      <Table.Cell>
        { is_owned && <Popup trigger={<Icon name="user" color="green" />} content="Contributed by current user" position="top center" /> }
      </Table.Cell>
      <ScoreElement {...score} popupPosition="right center" />
      <Table.Cell textAlign="right">{score.count}</Table.Cell>
      <Table.Cell>
        { scorecards_count > 0 ?
            <Popup content="View Scorecards"
              trigger={
                <Button primary icon="info"
                  as={Link} to={`${match.url}/protocols/${protocolId}/evidence/${id}`} />
                }
              />
          : (
            profile ?
              <Popup content="Add Scorecard"
                trigger={
                  <Button positive icon="plus"
                    as={Link} to={`${match.url}/protocols/${protocolId}/evidence/${id}/scorecards/new`}
                  />
                }
              />
            : <Button icon="plus" style={{ visibility: 'hidden' }} />
          )
        }
      </Table.Cell>
    </Table.Row>
  </>);
};

const ReferenceProtocolEvidenceTable = ({ protocolId }) => {
  const { referenceId } = useParams();

  const match = useRouteMatch();
  const { data: profile } = useProfile();

  const evidence = useLiteratureEvidence({ protocol: protocolId, reference: referenceId });
  return (<>
    <Table selectable unstackable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={2}>Factor</Table.HeaderCell>
          <Table.HeaderCell>Reference score</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Scorers</Table.HeaderCell>
          <Table.HeaderCell collapsing />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { _.map(evidence.data, (evidence) =>
            <ParameterEvidenceRow key={evidence.id} protocolId={protocolId} {...evidence} />
          )
        }
      </Table.Body>
    </Table>

    { profile && <>
      <Button floated="right" as={Link} to={`${match.url}/protocols/${protocolId}/evidence/new`} positive icon="plus" content="Add Factor" />
      <Divider fitted hidden clearing />
    </> }

  </>);
}

const ReferenceDetail = ({ onRemoved }) => {
  const { referenceId } = useParams();

  const history = useHistory();
  const match = useRouteMatch();

  const { data: profile } = useProfile();
  const reference = useReference(referenceId);
  const canModify = reference.data?.is_owned || profile?.is_staff;

  const deleteReference = useDeleteReference();
  const handleDeleteReference = () => deleteReference.mutate(referenceId, { onSuccess: onRemoved });

  const protocols = useProtocols({ reference: referenceId });

  // open a new Scorecard form directly after adding / locating the evidence
  const handleEvidenceAdded = (evidence) =>
    history.replace(`${match.url}/protocols/${evidence.parameter.protocol}/evidence/${evidence.id}/scorecards/new`);

  const handleCloseModal = () => history.replace(match.url);

  return (<>
    <Reference referenceId={referenceId} />

    { protocols.data?.length > 0 &&
      <>
        <Header as="h2">
          { profile && 
            <Button content="Add Protocol"
              icon="plus" positive  floated="right"
              as={Link} to={`${match.url}/evidence/new`}
            />
          }
          Protocols
        </Header>

        <Segment.Group>
          { _.map(protocols.data, (p) =>
              <Segment key={p.id}>
                <Header as="h3">
                  { p.name }
                  <Label horizontal color="teal">{_.find(PROTOCOL_TYPE_CHOICES, { value: p.protocol_type })?.text}</Label>
                  <Header.Subheader>Factors</Header.Subheader>
                </Header>
                <ReferenceProtocolEvidenceTable protocolId={String(p.id)} />
              </Segment>
            )
          }
        </Segment.Group>
      </>
    }

    { protocols.data?.length === 0 &&
        <Message warning>
          <Message.Content>
            <Message.Header>This Reference has not yet been evaluated for any Protocol.</Message.Header>
          </Message.Content>
          <Message.Content>
            { profile && <>
                <Button floated="right" positive icon="plus" content="Add Protocol" as={Link} to={`${match.url}/evidence/new`} />
                { canModify && <Button floated="right" negative icon="trash" content="Remove" onClick={handleDeleteReference} /> }
                <Divider fitted hidden clearing />
            </> }
          </Message.Content>
        </Message>
    }

    <Route path={`${match.path}/evidence/new`}>
      <LiteratureEvidenceAdd onAdded={handleEvidenceAdded} onClose={handleCloseModal} />
    </Route>

    <Route path={`${match.path}/protocols/:protocolId/evidence/new`}>
      <LiteratureProtocolEvidenceAdd onAdded={handleEvidenceAdded} modal onClose={handleCloseModal} />
    </Route>

    <Route path={`${match.path}/protocols/:protocolId/evidence/:evidenceId(\\d+)`}>
      <ParameterEvidenceScorecards onClose={() => history.push(match.url)} onRemoved={() => history.replace(match.url)} />
    </Route>

  </>);
};

export const ReferenceList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { data: profile } = useProfile();

  const { search } = useLocation();
  const { search: searchTerm='', protocol: protocolId } = qs.parse(search, { ignoreQueryPrefix: true });
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const handleSearchChanged = (e, { value }) => {
    const searchString = (value || protocolId) && '?' + qs.stringify({ protocol: protocolId, search: value });
    history.replace({ search: searchString });
  };

  const [post2010, setPost2010] = useState(null);
  const references = useReferences({ protocol: protocolId, search: debouncedSearchTerm, post2010 });

  const handleCloseEditModal = () => history.replace({ pathname: match.url, search });

  const selectedProtocol = useProtocol(protocolId);

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:referenceId(\\d+)`}>
          <Header as="h2" className="peers-blue">Reference details</Header>
          <ReferenceDetail onRemoved={() => history.replace(match.url)} />
        </Route>
        <Route>
          <Header as="h2" className="peers-blue">References</Header>
          {protocolId ? (
            <>
              <Button compact floated="right" primary content="Show all references" as={Link} to={match.url} />
              <Header as="h3" className="peers-blue">
                For Protocol "<u>{selectedProtocol.data?.name}</u>"
              </Header>
            </>
          ) : (
            <ProtocolSearchBox />
          )}

          <Divider hidden />

          <Grid columns="equal" stackable>
            <Grid.Column stretched>
              <Input icon="search" iconPosition="left" placeholder="Filter by title, DOI, or PubMed ID"
                action={{ icon: 'cancel', onClick: handleSearchChanged}}
                value={searchTerm} onChange={handleSearchChanged} 
                loading={searchTerm ? references.isFetching : false} 
              />
            </Grid.Column>
            <Grid.Column>
              { profile && <Button positive icon="plus" content="Add Reference" as={Link} to={`${match.url}/new`} /> }

              <Button.Group basic floated="right">
                <Button active={post2010 === null} content="All" onClick={() => setPost2010(null)} />
                <Button active={post2010 === true} content="Post-2010" onClick={() => setPost2010(true)}  />
                <Button active={post2010 === false} content="Pre-2010" onClick={() => setPost2010(false)}  />
              </Button.Group>
            </Grid.Column>
          </Grid>

          <Divider hidden />

          <Card.Group itemsPerRow={2} stackable>
            { _.map(references.data, (reference) =>
              <ReferenceCard key={reference.id}
                reference={reference}
                viewUrl={{ pathname: `${match.url}/${reference.id}`, search }}
                editUrl={{ pathname: `${match.url}/edit/${reference.id}`, search }}
              />
            ) }
          </Card.Group>
        </Route>
      </Switch>

      <Route path={`${match.path}/new`}>
        <ReferenceAdd
          onAdded={(data) => history.replace(`${match.url}/${data.id}`)}
          onClose={() => history.push(match.url)}
        />
      </Route>

      <Route path={`${match.path}/edit/:referenceId`}>
        <ReferenceModal edit size="large" dimmer="inverted" centered={false} onClose={handleCloseEditModal} />
      </Route>
    </>
  );
};

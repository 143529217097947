import { useLocation } from 'react-router-dom';
import { Divider, Header, Image, List, Segment } from 'semantic-ui-react';

import peersValueImg from './images/PEERS_value.png';
import peersDatabaseImg from './images/PEERS_database.png';

function FocusedSegment({ children, ...props }) {
  const { hash } = useLocation();
  const focused = hash === '#' + props.id;

  return (
    <Segment {...props} basic={!focused} color={focused ? 'teal' : undefined}>
      {children}
    </Segment>
  );
}

export function PeersMission() {
  return (
    <Segment basic size="large">
      <Header as="h2" className="peers-blue">
        Mission / Concept
      </Header>

      <p>
        PEERS is a platform that enables sharing of information on parameters
        (termed ‘factors’) that affect the robustness and reproducibility of
        preclinical outcomes.
      </p>

      <Segment basic padded="very" textAlign="center">
        <Image src={peersValueImg} />

        <Header as="p" size="tiny">
          Overview of relevant stakeholder groups and the added value and impact
          provided by PEERS
        </Header>
      </Segment>

      <Divider hidden />

      <p>
        The PEERS platform provides a fast and easy access to relevant
        information which scientists can use during all phases of their
        experiments. It follows that by considering all critical Factors
        influencing the experimental outcome, the quality of experimental
        studies and their reproducibility will increase. This, in turn, results
        in higher trust for journal editors in submitted manuscripts or for
        funding organizations that projects selected for funding will generate
        meaningful and useful data. It will also enhance translation from
        non-clinic to clinic (for biomedical research) and aid in the alignment
        of data quality expectations ensuring the generation of
        decision-enabling, reproducible data sets.
      </p>

      <Divider hidden />

      <Header as="h2">What problem is PEERS trying to solve?</Header>

      <p>
        Information about Factors that determine experimental robustness and can
        influence the outcome of an experiment is often either unclear or not
        available to scientists. One reason is that the level of detail to
        inform about these Factors is usually not provided in scientific
        publications. Scientists therefore rely on their individual experience
        and personal communications. As a result, scientists have only limited
        access to relevant information and have little opportunity to learn from
        each other on how to increase the robustness and reproducibility of
        their studies.
      </p>

      <p>
        Thus, there is a risk of missing critical elements which should have
        been considered at the very beginning of a new experiment: Have I
        thought about Factor X? Is Y likely to vary? Do I need to control for Z?
        How do I know which Factors do I need to monitor or report? Are there
        guidelines for this? Do my actions depend on the specific type of the
        experiment or model conducted?
      </p>

      <p>
        To address this problem, PEERS is built to aid scientists in determining
        which experimental Factors are most likely to affect the outcome of a
        specific test, model or assay and therefore should be considered during
        the design, execution and reporting stages of new studies.
      </p>

      <Divider hidden />

      <Header as="h2">How does PEERS work?</Header>

      <p>
        Collectively, there are many variables/Factors that can affect an
        experiment and its outcome. Importantly, these Factors have not yet been
        systematically considered or weighted. A proper catalogue of these
        influencing Factors is missing to date.{' '}
      </p>
      <p>
        PEERS fills this gap and guides scientists by advising which Factors
        need to be monitored and/or reported. To achieve this, PEERS provides
        two level of information/answers:
      </p>

      <List ordered as="ol">
        <List.Item as="li">
          Does Factor X influence the outcome of Protocol Y and should be
          considered during the different phases of an experiment? Here, the
          answer is either ‘Yes’, ‘No’ or ‘unclear’ (in case no or only
          conflicting experimental data for this Factor is available).
        </List.Item>
        <List.Item as="li">
          What is the strength of scientific evidence we have for the answer
          provided in #1? Here, PEERS offers a vetted, independent perspective
          by which the quality of information available for a Factor or Protocol
          can be judged and evaluated (see PEERS Scorecards).
        </List.Item>
      </List>

      <Segment basic padded="very" textAlign="center">
        <Image src={peersDatabaseImg} />

        <Header as="p" size="tiny">
          Overall structure of the PEERS database and its organization.
        </Header>
      </Segment>

      <Divider hidden />

      <p>
        The PEERS database is organized in 4 different domains which provide an
        increasing level of detail and understanding for the role different
        Factors play for selected Protocols of interest:{' '}
      </p>

      <FocusedSegment size="large" id="protocols">
        <Header as="h4">1) The experimental ‘protocol’</Header>
        <p>
          The PEERS database contains a searchable list of various in vitro and
          in vivo Protocols which are of interest for scientists in the
          biomedical research area. This list of Protocols will be constantly
          expanded to cover as many scientific fields as possible.
        </p>
      </FocusedSegment>

      <FocusedSegment size="large" id="Factors">
        <Header as="h4">2) The experimental ‘factor’</Header>
        <p>
          The experimental ‘factors’ are the central element of the PEERS
          database. Factors are defined as any aspect of a study that can affect
          its outcome. Thus, information how to incorporate them into the study
          design (e.g., ignore/control, monitor, report) is required. Users of
          the platform can search for these Factors depending on the Protocol as
          well as outcomes they are interested in.
        </p>
      </FocusedSegment>

      <FocusedSegment size="large" id="references">
        <Header as="h4">3) The published ‘references’</Header>
        <p>
          To identify and collate publications that study the importance of a
          specific Factor for a selected Protocol and its outcome, the PubMed
          and EMBASE databases are used. These include publications dealing with
          either a Factor of interest that had an effect on the method outcome
          or one that had no effect on the outcome when manipulated.
        </p>
      </FocusedSegment>

      <FocusedSegment size="large" id="scorecards">
        <Header as="h4">4) The PEERS Scorecards</Header>

        <p>
          For each Factor, a ‘quality of evidence’ analysis is conducted, based
          on the published literature.{' '}
        </p>
        <p>
          To provide a structured template to determine the quality of each
          selected publication, the so called PEERS Scorecards have been
          developed.{' '}
        </p>
        <p>
          These Scorecards with their evidence rating system were refined
          through multiple Delphi rounds within the PEERS Working Group and
          contain a checklist with two main domains (Methods and Results):
        </p>

        <List>
          <List.Item as="li">
            All elements of the Methods domain were based on ARRIVE 2.0
            “Essential 10” and recommendations of the EQIPD consortium (Percie
            du Sert et al., 2020; Vollert et al., 2020).{' '}
          </List.Item>
          <List.Item as="li">
            The Results domain aims at evaluating the quality and suitability of
            the published results and analyses.{' '}
          </List.Item>
        </List>

        <p>
          For each domain, a maximum score of 10 is awarded, if all items are
          sufficiently addressed. The Scorecards constitute a unique feature of
          the PEERS database because not only do they evaluate reporting of the
          methods in any paper, but also take into account the suitability and
          strength of the results presented.
        </p>
        <p>
          After adding multiple Scorecards for the same Factor to the database,
          PEERS then automatically calculates a score sum. If only one assessor
          has scored a single citation, the score of this citation is presented.
          Ideally, however, each citation is evaluated by two or more assessors
          to remove any source of bias. In this case, an average score of all
          Scorecards is presented, along with the calculated standard deviation,
          standard error and confidence intervals around the average score. This
          detailed information provides the user with a better understanding of
          the degree of consensus that the assessors reached evaluating the
          quality of the citation.{' '}
        </p>
        <p>
          Besides the number of assessors for each citation, the number of
          identified citations for each Factor needs to be considered when
          calculating the final score. When at least two assessors have scored
          two references, the system automatically starts a meta-analytic
          calculation process: a fixed-effect model is used and I2 test
          statistics are applied to evaluate the heterogenicity between
          assessors and citations. This tests both the consensus of the
          assessors for each citation as well as the consensus of the citation
          regarding the Factor of interest. Moreover, forest plots are generated
          with confidence intervals depicted, providing a bird’s eye view of all
          Scorecards that exist in the PEERS database for any given Factor or
          any given Protocol.{' '}
        </p>
        <p>
          The grading system is then simplified to establish the overall scores
          for each Factor into high (&gt;14/20), medium (5–13/20), and low
          (&lt;5/20) quality (or no evidence), which is displayed for users on
          the front-end of the platform.
        </p>
      </FocusedSegment>

      <Divider hidden />

      <Header as="h2">User interaction</Header>
      <p>
        PEERS allows users to search for Factors and parameters that are of
        relevance to their experiments and gives guidance on how to leverage
        these Factors to increase the quality, robustness and reproducibility of
        their research.
      </p>
      <p>
        PEERS also provides the opportunity for registered users to add new
        Protocols, Factors or Scorecards - thereby expanding the PEERS database
        and our understanding of the importance of each potential experimental
        Factor. All contributions (e.g. completed Scorecards) of scientists will
        be made findable, citable and searchable on the PEERS platform.
      </p>
    </Segment>
  );
}

import { Card, Header, Icon, Image, Segment } from 'semantic-ui-react';

export function CodeOfConduct() {
  return (
    <Segment size="large" basic>
      <Header as="h2" className="peers-blue">
        Code of conduct
      </Header>

      <p>
        This code is designed to provide all PEERS users with guidelines for
        appropriate professional conduct. As PEERS is a community-driven
        resource, we expect this community to be respectful and engaging to
        ensure that PEERS is useful and helpful to scientists at different
        stages of their career and in different research environments:
      </p>
      <p>
        Often users will be scoring the quality of methods and results presented
        in a scientific paper and these are bound to have consequences for other
        users, colleagues or authors of that paper and therefore, it is
        important to be respectful, fair and open. Users must not allow their
        personal prejudices or preferences to overshadow the scoring of any
        papers and must judge a paper purely on the content presented in it.
        Disagreements should be dealt with in a professional manner and when
        possible, informally.
      </p>
      <p>
        However, violations of the CoC will affect the user’s ability to
        contribute to the PEERS database and to score papers and engage with the
        wider PEERS community.
      </p>
    </Segment>
  );
}

export function Funding() {
  return (
    <Segment basic size="large">
      <Header as="h2" className="peers-blue">
        Funding
      </Header>

      <p>
        The development of PEERS was funded by Cohen Veterans Bioscience and
        grants COH-0011 from Steven A. Cohen.
      </p>

      <Header as="h3">About Cohen Veterans Bioscience</Header>

      <Image
        size="medium"
        floated="left"
        src={process.env.PUBLIC_URL + '/cvb-logo-jpg.jpg'}
      />
      <p>
        Cohen Veterans Bioscience is a non-profit 501(c)(3) biomedical research
        and technology organization dedicated to advancing brain health by
        fast-tracking precision diagnostics and tailored therapeutics.
      </p>
      <p>
        Learn more at{' '}
        <a className="inverted" href="https://www.cohenveteransbioscience.org">
          CohenVeteransBioscience.org
        </a>
      </p>
    </Segment>
  );
}

export function Team() {
  return (
    <Segment size="large" basic>
      <Header as="h2" className="peers-blue">
        Team
      </Header>

      <Header as="h3">PEERS Working Group</Header>
      <Card.Group itemsPerRow={3} stackable>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Christina Dalla </Card.Header>
            <Card.Description>
              National and Kapodistrian University of Athens (NKUA)
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Christoph Emmerich </Card.Header>
            <Card.Description>PAASP GmbH Heidelberg</Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Chantelle Ferland-Beckham </Card.Header>
            <Card.Description>Cohen Veterans Bioscience</Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Arnoud Herremans </Card.Header>
            <Card.Description>Y47 Consultancy</Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Konstantinos Karantzalos </Card.Header>
            <Card.Description>
              National Technical University of Athens (NTUA)
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Martien Kas </Card.Header>
            <Card.Description>University of Groningen</Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Nikolaos Kokras </Card.Header>
            <Card.Description>
              National and Kapodistrian University of Athens (NKUA)
            </Card.Description>
          </Card.Content>
        </Card>
        {/* <Card>
          <Image />
          <Card.Content>
            <Card.Header>Kostis Pristouris </Card.Header>
            <Card.Description>
              National Technical University of Athens (NTUA)
            </Card.Description>
          </Card.Content>
        </Card> */}
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Gernot Riedel </Card.Header>
            <Card.Description>University of Aberdeen</Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image />
          <Card.Content>
            <Card.Header>Annesha Sil </Card.Header>
            <Card.Description>University of Aberdeen</Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
      <Header as="h3">Stakeholders</Header>
      <p>
        The members of the PEERS Stakeholder Group will be listed here soon…
      </p>
    </Segment>
  );
}

export function Contact() {
  return (
    <Segment size="large" basic>
      <Header as="h2" className="peers-blue">
        Contact
      </Header>
      <p>
        Contact us by email to learn more about PEERS, how to contribute or
        collaborate with us.
      </p>
      <p>
        <Icon name="mail" /><a href="mailto:peers@preclinicaldataforum.org">peers@preclinicaldataforum.org</a>
      </p>
    </Segment>
  );
}
